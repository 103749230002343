import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import MasterCard from "../../../../assets/images/mastercard.png";
import Visa from "../../../../assets/images/visa.png";
import AmericanExpress from "../../../../assets/images/americanExpress.png";
import PayPal from "../../../../assets/images/payPal.png";
import AdBannner from "../../AdBanner/AdBanner";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import OurPartner from "../../ourPartners";
import DialogBox from "../../UI/dialogBox";
import "./mySubscriptionPlan.scss";
import PaymentModal from "../../UI/paymentModal";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment";
import Loader from "src/components/Loader";
import { toast } from "react-toastify";
import { Config } from "src/helpers/context";

const MySubscriptionPlan = ({ profileModal }) => {
  const navigate = useNavigate();
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [cancelSubscriptionOpen, setCancelSubscriptionOpen] = useState(false);
  const [updatePlanModal, setUpdatePlanModal] = useState(false);
  const [editPaymentModal, setEditPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myPlanDetails, setMyPlanDetails] = useState({});
  const [planUpdateLoading, setPlanUpdateLoading] = useState(false);

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = (ReduxAdsData) => {
    let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  useEffect(() => {
    fetchGetMyPlanDetails();
  }, []);

  const fetchGetMyPlanDetails = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/get-purchased-plan`,
      );
      if (status === 200) {
        setLoading(false);

        setMyPlanDetails(data?.getUserPlanDetails);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUpdateMyPlan = () => {
    setUpdatePlanModal(true);
  };

  const handleUpdateMyPlanClose = () => {
    setUpdatePlanModal(false);
  };

  const handleCancelSubscription = () => {
    setCancelSubscriptionOpen(true);
  };

  const handleCancelCloseSubscription = () => {
    setCancelSubscriptionOpen(false);
  };

  const handleEditPaymentMethod = () => {
    setEditPaymentModal(true);
  };

  const handleEditPaymentMethodClose = () => {
    setEditPaymentModal(false);
  };

  const handleSubscriptionPlanDelete = async (id, type) => {
    setPlanUpdateLoading(true);
    let payload = {
      SubscriptionPlanId: id,
    };
    try {
      const { status, data } = await axiosInstance.put(
        `/subscription/cancel-plan?type=${
          type == "update" ? "upgradePlan" : "cancel"
        }&plateform=web`,
        payload,
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        setPlanUpdateLoading(false);
        if (type == "update") {
          navigate("/membership");
          setUpdatePlanModal(false);
        } else if (type == "cancel") {
          navigate("/membership");
          setCancelSubscriptionOpen(false);
        } else {
        }
      } else {
        if (type == "update") {
          setUpdatePlanModal(false);
        } else if (type == "cancel") {
          setCancelSubscriptionOpen(false);
        } else {
        }
        setPlanUpdateLoading(false);
        toast.error(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (error) {
      if (type == "update") {
        setUpdatePlanModal(false);
      } else if (type == "cancel") {
        setCancelSubscriptionOpen(false);
      } else {
      }
      setPlanUpdateLoading(false);
      toast.error(error?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };
  return (
    <>
      {profileModal !== "profile-modal" && (
        <Helmet>
          <title>Mymembership</title>
          <meta name="description" content="" />
        </Helmet>
      )}
      <Box className="content-wrap">
        {profileModal !== "profile-modal" && (
          <Box className="mobile-banner">
            {fetchPageHeaderAds("218px", banner)}
          </Box>
        )}
        <Box className="my-subscription-wrap">
          {profileModal !== "profile-modal" && (
            <Box className="my-subscription-header">
              <Box className="bredcrumn-details">
                <Box className="bredcrumn-wrap">
                  <Breadcrumbs
                    separator="/"
                    aria-label="breadcrumb"
                    className="breadcrumb"
                  >
                    <Link underline="hover" color="inherit" to="/">
                      Home
                    </Link>
                    <Typography>My Membership</Typography>
                  </Breadcrumbs>
                </Box>
              </Box>
              <Typography variant="h1">My Membership</Typography>
            </Box>
          )}
          {loading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <Box
              className={
                profileModal === "profile-modal"
                  ? "profile-my-subscription-details my-subscription-details"
                  : "my-subscription-details"
              }
            >
              <Box className="my-subscription-plan">
                <Box className="my-plan">
                  <Typography>
                    {myPlanDetails?.status == "cancelled"
                      ? `Your Membership expiring on - ${
                          myPlanDetails?.expireAt
                            ? moment(myPlanDetails?.expireAt).format(
                                "DD/MM/YYYY",
                              )
                            : "-"
                        }`
                      : "Membership information"}
                  </Typography>
                </Box>
                <Box className="plan-details-wrap">
                  <Box className="plan-details plan-border-bottom">
                    <Typography className="plan-type">
                      Membership Type
                    </Typography>

                    <Typography className="plan-information mobile-plan-information">
                      {myPlanDetails ? (
                        <>
                          {myPlanDetails?.PlanName && (
                            <span className="plan-name">
                              {myPlanDetails?.PlanName}
                            </span>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box className="plan-details-wrap">
                  <Box className="plan-details plan-border-bottom">
                    <Typography className="plan-type">Amount</Typography>

                    <Typography className="plan-information mobile-plan-information">
                      {myPlanDetails?.amount || myPlanDetails?.amount == "0" ? (
                        <>
                          {`${"$" + myPlanDetails?.amount}/${
                            myPlanDetails?.duration ?? "-"
                          }`}
                        </>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box className="plan-details-wrap">
                  <Box className="plan-details plan-border-bottom">
                    <Typography className="plan-type">
                      Membership Platform
                    </Typography>

                    <Typography className="plan-information mobile-plan-information">
                      {myPlanDetails?.plateform}
                    </Typography>
                  </Box>
                </Box>
                <Box className="plan-details-wrap">
                  <Box className="plan-details plan-border-bottom">
                    <Typography className="plan-type">Status</Typography>
                    <Typography
                      className={
                        myPlanDetails?.status == "active"
                          ? "plan-information plan-active"
                          : "plan-information plan-deactivate"
                      }
                    >
                      {myPlanDetails?.status && <span className="dots"></span>}
                      {myPlanDetails
                        ? myPlanDetails?.status == "active"
                          ? "Active"
                          : "Cancelled"
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
                <Box className="plan-details-wrap">
                  <Box className="plan-details plan-border-bottom">
                    <Typography className="plan-type">
                      {myPlanDetails?.status == "cancelled"
                        ? "Plan Expiring"
                        : "Renewal Date"}
                    </Typography>
                    <Typography className="plan-information">
                      {myPlanDetails?.expireAt
                        ? moment(myPlanDetails?.expireAt).format("DD/MM/YYYY")
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
                {myPlanDetails?.plateform == "web" &&
                  myPlanDetails?.amount !== "0" && (
                    <Box className="plan-details-wrap">
                      <Box className="plan-details">
                        <Typography className="plan-type">
                          Payment Method
                        </Typography>
                        <Box>
                          <Typography className="plan-information">
                            {myPlanDetails?.card ? (
                              <>
                                <span className="card-img">
                                  <img
                                    src={
                                      myPlanDetails?.card?.brand == "VISA"
                                        ? Visa
                                        : MasterCard
                                    }
                                    alt="card"
                                  />
                                </span>
                                <span className="card-name">
                                  {myPlanDetails?.card?.brand + " "} card ending
                                  in
                                  {" " + myPlanDetails?.card?.card_number}
                                </span>
                              </>
                            ) : (
                              "-"
                            )}
                          </Typography>
                          {/* <Typography
                    className="edit-payment"
                    onClick={() => handleEditPaymentMethod()}
                  >
                    Edit payment method
                  </Typography> */}
                        </Box>
                      </Box>
                    </Box>
                  )}
              </Box>
              {myPlanDetails && myPlanDetails?.plateform == "web" ? (
                <Box className="plan-update-btn">
                  {/* <Button
                  className="update-plan-btn"
                  onClick={() =>
                    myPlanDetails?.status == "cancelled" ||
                    myPlanDetails?.key == "free"
                      ? navigate("/subscription-payment")
                      : handleUpdateMyPlan()
                  }
                >
                  {myPlanDetails?.status == "cancelled"
                    ? "Upgrade now!"
                    : "Update my plan"}
                </Button> */}
                  {myPlanDetails?.status == "cancelled" ||
                  myPlanDetails?.key == "free" ? (
                    ""
                  ) : (
                    <span
                      className="cancel-subscription"
                      onClick={() => handleCancelSubscription()}
                    >
                      Cancel membership
                    </span>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          )}
        </Box>
        <OurPartner />
      </Box>
      <DialogBox
        open={cancelSubscriptionOpen}
        onClose={handleCancelCloseSubscription}
        handleClose={handleCancelCloseSubscription}
        title={false}
        className="my-subscription-modal"
        content={
          <>
            <Box className="delete-subscription-dialogbox">
              <Typography className="delete-subscription-title">
                Are you sure you want to cancel your membership?
              </Typography>
              <Box className="delete-subscription-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel"
                  disabled={planUpdateLoading}
                  onClick={() => {
                    handleSubscriptionPlanDelete(
                      myPlanDetails?.SubscriptionPlanId,
                      "cancel",
                    );
                  }}
                >
                  Cancel active membership
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-not-now"
                  onClick={() => handleCancelCloseSubscription()}
                >
                  Not now
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={true}
      />
      <DialogBox
        open={updatePlanModal}
        onClose={handleUpdateMyPlanClose}
        handleClose={handleUpdateMyPlanClose}
        title={false}
        className="my-subscription-modal"
        content={
          <>
            <Box className="delete-subscription-dialogbox">
              <Typography className="delete-subscription-title">
                To update your plan, you must first cancel your current active
                membership.
              </Typography>
              <Box className="delete-subscription-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel"
                  disabled={planUpdateLoading}
                  onClick={() => {
                    handleSubscriptionPlanDelete(
                      myPlanDetails?.SubscriptionPlanId,
                      "update",
                    );
                  }}
                >
                  Cancel active membership
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-not-now"
                  onClick={() => handleUpdateMyPlanClose()}
                >
                  Not now
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={true}
      />
      <Dialog
        onClose={handleEditPaymentMethodClose}
        aria-labelledby="customized-dialog-title"
        open={editPaymentModal}
        className="payment-modal"
      >
        <DialogTitle className="modal-title">
          <Typography variant="h6" className="title">
            Edit Payment method
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleEditPaymentMethodClose()}
            className="close-icon"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="payment-details">
          <PaymentModal
            paymentModalOpen={editPaymentModal}
            setPaymentModalOpen={setEditPaymentModal}
            handleClose={handleEditPaymentMethodClose}
            // fetchGetPlanDetails={fetchGetPlanDetails}
            // planPrice={planPrice}
            // planId={planId}
            // planDetails={planDetails}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MySubscriptionPlan;
