import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Button,
  Tabs,
  Tab,
  ClickAwayListener,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { IntlContext } from "src/App";
import Select, { components } from "react-select";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Tooltip from "@mui/material/Tooltip";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { ReactComponent as Order } from "../../../../../assets/images/icons/order.svg";
import { ReactComponent as Polyhon } from "../../../../../assets/images/icons/polygon.svg";
import { ReactComponent as BookIcon } from "../../../../../assets/images/icons/bookicon.svg";
import { ReactComponent as AddBookIcon } from "../../../../../assets/images/add-blackbook.svg";
import { ReactComponent as TooltipArrow } from "../../../../../assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as BlackBookIcon } from "../../../../../assets/images/icons/blackbookicon.svg";
import { ReactComponent as BlackbookRight } from "../../../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../../../assets/images/icons/blackbookPlus.svg";
import { ReactComponent as RightBlack } from "../../../../../assets/images/icons/rightblack.svg";
import { ReactComponent as PlusBlack } from "../../../../../assets/images/icons/plusblack.svg";
import { ReactComponent as CollepseClose } from "../../../../../assets/images/icons/collepse-close.svg";
import { ReactComponent as CollepseOpen } from "../../../../../assets/images/icons/collepse-open.svg";
import { ReactComponent as CompareOdds } from "../../../../../assets/images/icons/compareOdds.svg";
import { ReactComponent as SpeedMap } from "../../../../../assets/images/icons/speedMap.svg";
import { ReactComponent as FluctuationGraph } from "../../../../../assets/images/icons/fluctuationGraph.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";

import { fetchInterval } from "../../../../../helpers/store/Actions/interval";
import { Config } from "../../../../../helpers/context/config";

import Bet365 from "../../../../../assets/images/bookmakers/bet365-thumb.svg";
import UniBet from "../../../../../assets/images/bookmakers/unibet-thumb.svg";
import BookMaker from "../../../../../assets/images/bookmakers/bookmakers-thumb.svg";
import Neds from "../../../../../assets/images/bookmakers/neds-thumb.svg";
import PlayUp from "../../../../../assets/images/bookmakers/playup-thumb.svg";
import BetStar from "../../../../../assets/images/bookmakers/betstar-thumb.svg";
// import ViceBet from "../../../../../assets/images/bookmakers/vice-bet-thumb.svg";
import BlueBet from "../../../../../assets/images/bookmakers/BlueBet-thumb.png";
import TopSport from "../../../../../assets/images/bookmakers/top-sport-thumb.svg";
import betFair from "../../../../../assets/images/bookmakers/betfair-thumb.svg";
import BoomBet from "../../../../../assets/images/bookmakers/BoomBet.png";
import LadBrokes from "../../../../../assets/images/bookmakers/Ladbrokes-thumb.png";
import Draftstars from "../../../../../assets/images/bookmakers/DraftStars.png";
import SouthernCrossBet from "../../../../../assets/images/bookmakers/SouthernCrossBet.png";
import PuntOnDogs from "../../../../../assets/images/bookmakers/Puntondogs.webp";
import BetRight from "../../../../../assets/images/new-bookmakers/BETRIGHT-icon.webp";
import EliteBet from "../../../../../assets/images/new-bookmakers/ELITEBET-icon.webp";
import GetSetBet from "../../../../../assets/images/new-bookmakers/GSB-icon.webp";
import RunnerModal from "./runnerModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import _ from "lodash";
import BlackBookModal from "src/views/component/UI/blackBookModal";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchFromStorage } from "src/helpers/context";
import FluctuationGraphPage from "./fluctuationGraph";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { ToastContainer, toast } from "react-toastify";
import NoDataComp from "src/views/component/UI/NoData";
import { release } from "src/helpers/context/release";
import DefaultImg from "src/assets/images/smartb_default.png";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const options = [
  { value: "", label: "ODDS TYPE", disabled: true },
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" },
  { value: "3", label: "Win Tote" },
  { value: "4", label: "Place Tote" },
  // { value: "4", label: "Starting Price" },
];
const data = [
  { name: "Group A", value: 12 },
  { name: "Group C", value: 24 },
  { name: "Group D", value: 64 },
];
const COLORS = ["#E2662C", "#1C9A6C", "#595959"];
let id = 0;

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    boxShadow: "1px 0px 6px 0px rgba(0,0,0,0.16)",
  },
  body: {
    left: 0,
    position: "sticky",
    boxShadow: "1px 0px 6px 0px rgba(0,0,0,0.16)",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const runnerTabData = [
  {
    id: 0,
    name: "Compare Odds",
    icon: <CompareOdds />,
  },
  // {
  //   id: 1,
  //   name: "Speed Map",
  //   icon: <SpeedMap />
  // },
  {
    id: 1,
    name: "Fluctuation Graph",
    icon: <FluctuationGraph />,
  },
];

export const RunnerTable = ({
  runnerInfo,
  isrunnnerLoading,
  raceId,
  isResult,
  raceTrackdata,
  fetchRaceRunner,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const { setApiInfo } = useAutoSaveStore();
  const BetSlipToggleData = useSelector(
    (state) => state?.reduxData?.BetSlipToggle
  );
  const BetslipData = useSelector((state) => state?.reduxData?.BetSlipData);
  const BookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData
  );
  const reduxUserData = useSelector(
    (state) => state?.reduxData?.SubscripitionData
  );
  // const [defaultselectedOption, defaultsetSelectedOption] = useState("1");
  const [selectedOption, setSelectedOption] = useState("1");
  const [runnerInfoData, setrunnerInfoData] = useState([]);
  // const [runnerExtraInfoData, setrunnerExtraInfoData] = useState();
  const [isRunnerLoad, setisRunnerLoad] = useState(false);

  // Runner ExtraInfo State
  const [raceParticipantData, setRaceParticipantData] = useState();
  const [runnerComment, setrunnerComment] = useState("");
  // const [runnerLastStart, setrunnerLastStart] = useState();
  const [runnerSire, setrunnerSire] = useState();
  const [runnerDam, setrunnerDam] = useState();
  // const [runnerFirm, setrunnerFirm] = useState();
  // const [runnerGood, setrunnerGood] = useState();
  // const [runnerSoft, setrunnerSoft] = useState();
  // const [runnerHeavy, setrunnerHeavy] = useState();
  const [runnerPrvRuns, setrunnerPrvRuns] = useState();
  const [runnerMoney, setrunnerMoney] = useState();
  const [runnerPastPerfomance, setrunnerPastPerfomance] = useState();
  // const [runnerSpeedMap, setrunnerSpeedMap] = useState();
  const [runnerInfomation, setrunnerInfomation] = useState("");
  // const [runnerOwnerInfo, setrunnerOwnerInfo] = useState();
  // const [runnerTrainerInfo, setrunnerTrainerInfo] = useState();
  // const [runnerType, setrunnerType] = useState();
  // const [runnerLast, setrunnerLast] = useState();
  const [timeinterval, setTimeInterval] = useState();
  const [apiTimeout, setAPiTimeout] = useState();

  const [value, setValue] = React.useState(0);
  const [seeall, setseeall] = useState(false);
  const [overFlow, setOverFLow] = useState(true);
  const [isOrder, setIsOrder] = useState(false);

  const [open, setOpen] = useState(-1);
  const [openSeall, setopenSeall] = useState(-1);
  const [isInputModalOpen, setisInputModalOpen] = useState(false);
  const [isFullFormExpand, setisFullFormExpand] = useState(false);
  const [isSeeallFullFormExpand, setisSeeallFullFormExpand] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [MobileBest5Open, setMobileBest5Open] = useState(-1);
  const [scrollX, setscrollX] = useState("left");
  const [oddsType, setOddsType] = useState({ marketId: 1, oddKey: 1 });
  const [oddsLoader, setOddsLoader] = useState(false);
  const [oddsApiCount, setOddsAPiCount] = useState(0);
  const [ProviderData, setProviderData] = useState([]);
  const [sponsoredId, setSponsoredId] = useState([]);
  const [ModalDataId, setModalDataId] = useState([]);
  const [pageHeadingData, setPageHeadingData] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [BBopen, setBBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [runnerTabValue, setRunnerTabValue] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [BKId, setBKId] = useState(0);
  const [topBookMakersData, setTopBookMakersData] = useState([]);
  const [recommendedBookmakersData, setRecommendedBookmakersData] = useState(
    []
  );
  const [userData, setUserData] = useState({});

  const localAuth = fetchFromStorage("auth_token");

  useEffect(() => {
    if (reduxUserData && reduxUserData !== undefined) {
      setUserData(reduxUserData);
    }
  }, [reduxUserData]);

  useEffect(() => {
    if (release[Config.release]?.rightSideBar) {
      fetchTopBookmakers();
    }
  }, []);

  const handleModal = () => {
    setBBOpen(true);
  };

  const handleClose = () => {
    setBBOpen(false);
  };

  const fetchTopBookmakers = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `apiProviders/bookKeepers`
      );
      if (status === 200) {
        setLoading(false);

        const bookMakersData = data?.result;
        const isFeaturedData = bookMakersData?.filter(
          (item) => item?.isFeatured === true
        );
        const recommendedBookmaker = bookMakersData?.filter(
          (item) => item?.isFeatured === false
        );
        const sortedData = isFeaturedData?.sort(
          (a, b) => a.featured_order - b.featured_order
        );
        const recommendedSortedData = recommendedBookmaker?.sort(
          (a, b) => a.featured_order - b.featured_order
        );
        setTopBookMakersData(sortedData);
        setRecommendedBookmakersData(recommendedSortedData);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBlackBookModal = (title, id, type) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    handleModal();
  };

  let sportId = params.sportId;
  const dispatch = useDispatch();
  const classes = useStyles();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const handleChangeTable = () => {
    setseeall(!seeall);
    setOpen(-1);
    setopenSeall(-1);
    setOverFLow(true);
    setisFullFormExpand(false);
    setisSeeallFullFormExpand(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    clearInterval(timeinterval);
    clearTimeout(apiTimeout);
  }, [raceId]);

  useEffect(() => {
    GetSponsoredOdds();
    // fetchTableHeading()
    // fetchBookKeeper()
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    return () => clearInterval(timeinterval);
  }, []);

  const fetchTableHeading = async (Provider) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders?SportId=1,2,3`
      );
      if (status === 200) {
        let filteredHeading = data?.result?.filter((item) =>
          Provider?.includes(item?.BookKeeperId)
        );
        setPageHeadingData(filteredHeading);
      } else {
      }
    } catch (err) { }
  };
  useEffect(() => {
    if (raceId) {
      fetchOddsData(raceId, true);
      fetchLatestOdds(raceId);
    }

    if (!isResult) {
      let timeout = setTimeout(() => {
        if (raceId) {
          fetchOddsData(raceId, false);
        }
        let Interval = setInterval(() => {
          if (raceId) {
            fetchOddsData(raceId, false);
          }
        }, 30000);
        setTimeInterval(Interval);
        dispatch(fetchInterval(Interval));
      }, 15000);
      setAPiTimeout(timeout);
      dispatch(fetchInterval(timeout));
    }
    setOpen(-1);
    setopenSeall(-1);
    setisFullFormExpand(false);
    setisSeeallFullFormExpand(false);
    return () => clearInterval(timeinterval);
  }, [runnerInfo]);

  const handleTableOpen = (isColleps) => {
    if (overFlow == true && isColleps == "isColleps") {
      setscrollX("left");
      var container = document.getElementById("sticky-race-bookmakersodd");
      var scrollCompleted = 0;
      var slideVar = setInterval(function () {
        container.scrollLeft -= 10;
        scrollCompleted += 10;
        if (scrollCompleted >= 600) {
          window.clearInterval(slideVar);
        }
      }, 10);
    }
  };
  const handleExpandfullForm = () => {
    setisFullFormExpand(!isFullFormExpand);
    setOpen(-1);
  };
  const handleSeeallExpandfullForm = () => {
    setisSeeallFullFormExpand(!isSeeallFullFormExpand);
    setopenSeall(-1);
    setOverFLow(false);
  };
  const SlideTable = (direction) => {
    var container = document.getElementById("runner-tab-table");
    var scrollCompleted = 0;
    var slideVar = setInterval(function () {
      if (direction == "left") {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 600) {
        window.clearInterval(slideVar);
      }
    }, 10);
  };
  const SlideSeeAllTable = (direction) => {
    setscrollX(direction);
    var container = document.getElementById("sticky-race-bookmakersodd");
    var scrollCompleted = 0;
    var slideVar = setInterval(function () {
      if (direction == "left") {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 900) {
        window.clearInterval(slideVar);
      }
    }, 10);
  };

  const oddsTypeChange = (e) => {
    setSelectedOption(e?.value);
    switch (e?.value) {
      case "1":
        setOddsType({ marketId: 1, oddKey: 1 });
        break;
      case "2":
        setOddsType({ marketId: 1, oddKey: 2 });
        break;
      case "3":
        setOddsType({ marketId: 2, oddKey: 1 });
        break;
      case "4":
        setOddsType({ marketId: 2, oddKey: 2 });
        break;
      default:
        setOddsType({ marketId: 1, oddKey: 1 });
    }
  };
  useEffect(() => {
    clearInterval(timeinterval);
    clearTimeout(apiTimeout);
    if (raceId) {
      fetchOddsData(raceId, true);
      fetchLatestOdds(raceId);
    }
    if (!isResult) {
      let timeout = setTimeout(() => {
        if (raceId) {
          fetchOddsData(raceId, false);
        }
        let Interval = setInterval(() => {
          if (raceId) {
            fetchOddsData(raceId, false);
          }
        }, 30000);
        setTimeInterval(Interval);
        dispatch(fetchInterval(Interval));
      }, 15000);
      setAPiTimeout(timeout);
      dispatch(fetchInterval(timeout));
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isResult) {
      clearInterval(timeinterval);
      clearTimeout(apiTimeout);
    }
  }, [isResult]);

  useEffect(() => {
    clearInterval(timeinterval);
    clearTimeout(apiTimeout);
  }, [params]);

  const fetchOddsData = async (raceId, isFirstTime) => {
    if (isFirstTime) {
      setOddsLoader(true);
    }
    let runnerInfoData = runnerInfo?.map((obj) => {
      return {
        ...obj,
        RunnerInfos: obj?.RunnerInfos?.map((item) => {
          return {
            ...item,
            info: item?.info ? JSON.parse(item?.info) : "",
          };
        }),
      };
    });
    try {
      const { status, data } = await axiosInstance.get(
        `events/getOddsByrace/${raceId}?marketId=${oddsType?.marketId}&oddKey=${oddsType?.oddKey}`
      );
      if (status === 200) {
        setOddsLoader(false);
        setOddsAPiCount(oddsApiCount + 1);

        let Provider = [];
        let ProviderListfirstRunner = data?.marketRelation?.[0]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          }
        );
        let ProviderListsecondRunner = data?.marketRelation?.[1]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          }
        );
        let ProviderListthirdRunner = data?.marketRelation?.[2]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          }
        );
        const uniqueArray = _.uniq(Provider);
        setProviderData(uniqueArray);
        // fetchTableHeading(uniqueArray)
        // hideSeeAllOddsColumn(data?.marketRelation)
        let newData = runnerInfoData?.map((obj) => {
          return {
            ...obj,
            oddsData: data?.marketRelation
              ?.map((item) => {
                return item?.RacingParticipantId == obj?.id ? item : [];
              })
              ?.filter((x) => {
                return x?.data?.length > 0;
              }),
          };
        });
        setrunnerInfoData(newData);
      }
    } catch (err) { }
  };
  const fetchLatestOdds = async (raceId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `sync/oddOnDemand/${raceId}`
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleRunnerOrder = () => {
    setIsOrder(!isOrder);
    if (isOrder) {
      const runnerDescending = [...runnerInfoData]?.sort((a, b) => a.id - b.id);
      setrunnerInfoData(runnerDescending);
    } else {
      const runnerDescending = [...runnerInfoData]?.sort((a, b) => b.id - a.id);
      setrunnerInfoData(runnerDescending);
    }
  };
  const toggleInputModal = () => {
    setisInputModalOpen(false);
  };
  const handleOpen = (data, id) => {
    setisInputModalOpen(true);
    setmodalData(data);
    setModalDataId(id);
  };
  const [modalData, setmodalData] = useState("");

  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    setBKId(BookKeeperId);
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(sportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item, oddsType) => {
    const newData = raceTrackdata?.ApiRaceIdentifiers?.map((obj) => {
      const BookkeeperObj = obj?.provider?.BookKeeperProviders?.filter(
        (item) => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null,
      };
    });

    const filteredData = newData?.filter?.(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let imageUrl =
      oddsType == "currentbest"
        ? iconData?.currentBest_logo
        : iconData?.long_logo;
    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId
              }~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          {oddsType == "currentbest" ? (
            <img
              className="currentbest-bookmaker-thumb"
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              alt="Odds Icon"
              onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
            />
          ) : (
            <Box className="odd-img">
              <img
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
              />
            </Box>
          )}
        </a>
      ) : (
        <></>
      );
    } else if (BookKeeperId == 13) {
      const fetchUrlArray = filteredData?.[0]?.url?.split("/");
      const fetchMarketId = fetchUrlArray?.[fetchUrlArray?.length - 1];
      const isMobile = () => {
        const userAgent = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      };
      const betFairUrl = isMobile()
        ? filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link
        : filteredData?.[0]?.url
          ? `https://www.betfair.com.au/exchange/plus/${params?.sportId == 3 ? "greyhound-racing" : "horse-racing"}/market/${fetchMarketId}`
          : iconData?.affiliate_link;
      return (
        <Box className="current-best-odds-icon">
          <a href={betFairUrl} target="_blank" rel="noopener noreferrer">
            {oddsType == "currentbest" ? (
              <img
                className="currentbest-bookmaker-thumb"
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
              />
            ) : (
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                  alt="Odds Icon"
                  onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
                />
              </Box>
            )}
          </a>
        </Box>
      );
    } else {
      return (
        <Box className="current-best-odds-icon">
          <a
            href={
              localAuth
                ? filteredData?.length > 0 && filteredData?.[0]?.url
                  ? filteredData?.[0]?.url + `?Referrer=SmartB`
                  : iconData?.affiliate_link
                : "#"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {oddsType == "currentbest" ? (
              <img
                className="currentbest-bookmaker-thumb"
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                alt="Odds Icon"
                onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
              />
            ) : (
              <Box className="odd-img">
                <img
                  src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                  alt="Odds Icon"
                  onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
                />
              </Box>
            )}
          </a>
        </Box>
      );
    }
  };
  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    oddsType
  ) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];

    let isAdded = BetslipData?.some((el) => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId ==
        BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(params?.raceId)
      )
        return true;
      else return false;
    });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8)

    //   const Odds = Bet365Data?.[0]?.RaceOdds?.[0]
    //   return (
    //     Bet365Data && Bet365Data?.length > 0 ?
    //       (<a href={Odds?.providerMarketId && Odds?.providerParticipantId ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId
    //         }~${Odds?.intValue}~1&bet=1` : `${iconData?.affiliate_link}`} target="_blank" rel="noopener noreferrer" className="odds-link" >  {odds === "SP" ? "SP" : Number(odds).toFixed(2)} </a>
    //       ) : (<></>)
    //   )
    // } else {

    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      // </a>
      oddsType == "currentbest" ? (
        <Box
          className={`current-best-odds-value ${isAdded ? "active-odd-style" : ""
            }`}
        >
          {localAuth ? (
            <span
              className={
                isAdded && IsBetslip === "betslip"
                  ? "betslip-added odds-link"
                  : "odds-link"
              }
              onClick={() => {
                IsBetslip === "nobetslip"
                  ? handleBookkeeperCounter(BookKeeperId, type)
                  : isAdded
                    ? handleDeleteBetslip(BookKeeperId, item)
                    : handleAddToBetSlip(BookKeeperId, type, item, fulldata);
              }}
            >
              {IsBetslip === "betslip" && !isResult ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          ) : (
            <span></span>
          )}
        </Box>
      ) : (
        <Box
          className={`odds-style  ${isAdded ? "active-odd-style" : ""}`}
          onClick={() => {
            IsBetslip === "nobetslip"
              ? handleBookkeeperCounter(BookKeeperId, type)
              : isAdded
                ? handleDeleteBetslip(BookKeeperId, item)
                : handleAddToBetSlip(BookKeeperId, type, item, fulldata);
          }}
        >
          {IsBetslip === "betslip" && !isResult ? (
            <Tooltip title="Bet" className="bet-tooltip" placement="top">
              <Typography className="odds bet-now">
                {" "}
                {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
              </Typography>
            </Tooltip>
          ) : (
            <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
          )}
        </Box>
      )
    );
    // }
  };
  const handleAddToBetSlip = async (BookKeeperId, type, item, fulldata) => {
    handleBookkeeperCounter(BookKeeperId, type);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );

    let payload = {
      sportId: Number(params?.sportId),
      eventId: Number(params?.raceId),
      teamId: BookKeeperData?.[0]?.RacingParticipantId,
      bookKeeperId: BookKeeperId,
      betOfferId: BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId,
    };
    if (isLogin) {
      if (!isResult) {
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      if (!isResult) {
        setApiInfo({
          api: `betSlipCard/BetSlipCard`,
          payload: payload,
          method: "post",
        });
      }
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, item) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let delslip = BetslipData?.filter((el) => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId ==
        BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId &&
        el?.eventId == Number(params?.raceId)
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        let chageData = BetslipData?.filter((obj) => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) { }
  };
  const fetchBestOpenValue = (data, OddsFluc) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })

    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno == 0) {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          return fetchClickableOdds(
            maxno,
            providerid?.[0],
            "header",
            data,
            [],
            "nobetslip"
          );
        } else {
          return "-";
        }
      } else {
        let providerid = data
          ?.map((obj) => {
            if (
              obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue === maxno
            ) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);
        return fetchClickableOdds(
          maxno,
          providerid?.[0],
          "header",
          data,
          [],
          "nobetslip"
        );
      }
      // return maxno;
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchBestOpenIcon = (data, OddsFluc) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (
              obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue === maxno
            ) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (
                obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === newmaxno
              ) {
                return obj?.BookKeeperId;
              }
            })
            .filter((x) => x !== undefined);
          return oddsicon(providerid?.[0], "header", data);
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  };
  const fetchCurrentBestValue = (data, fulldata) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return fetchClickableOdds(
          maxno,
          providerid?.[0],
          "header",
          data,
          fulldata,
          "betslip",
          "currentbest"
        );
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };
  const fetchCurrentBestIcon = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header", data, "currentbest");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const fetchFeaturedOdds = (item, BookKeeperId, fulldata) => {
    // let marketData = item?.oddsData?.[0]?.data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let datas = item
      ?.map((obj) => {
        if (obj?.BookKeeperId === BookKeeperId) {
          return {
            odds:
              obj?.RaceOdds?.[0]?.intValue !== 0
                ? obj?.RaceOdds?.[0]?.intValue
                : "SP",
            provider: obj?.BookKeeperId,
          };
        }
      })
      .filter((x) => x !== undefined);
    if (datas?.length > 0) {
      // return fetchClickableOdds(datas?.[0]?.odds, datas?.[0]?.provider, "header", null, null, "betslip")
      return (
        <Box className="odds-box-style">
          {fetchClickableOdds(
            datas?.[0]?.odds,
            BookKeeperId,
            "header",
            item,
            fulldata,
            "betslip",
            "featuredOdds"
          )}
          {oddsicon(BookKeeperId, "header", item, "featuredOdds")}
          {/* <Box className="odd-img">
                <img
                  src={
                    item?.long_logo
                      ? Config.mediaURL + item?.long_logo
                      : DefaultImg
                  }
                  alt="bk"
                />
              </Box> */}
        </Box>
      );
    } else {
      return null;
    }
  };
  const fetchTop5Odds = (data, isResult) => {
    let filledOdds = data
      ?.filter((item) => {
        return item?.RaceOdds?.length > 0;
      })
      ?.sort((a, b) => b?.RaceOdds?.[0]?.intValue - a?.RaceOdds?.[0]?.intValue);
    let BlankOdds = data?.filter((item) => {
      return item?.RaceOdds?.length == 0;
    });
    let oddsSort = filledOdds || BlankOdds ? [...filledOdds, ...BlankOdds] : [];
    let finalOdds =
      oddsSort?.length > 0 ? (!isResult ? oddsSort : [oddsSort?.[1]]) : [];
    let Top5Odds =
      finalOdds?.length > 0 ? (
        finalOdds?.map((obj) => {
          return (
            <>
              <TableCell className="next5odds-icon">
                {obj?.RaceOdds?.[0]?.intValue ? (
                  <>
                    <Box>
                      {obj?.RaceOdds?.[0]?.intValue !== 0
                        ? obj?.RaceOdds?.[0]?.intValue
                        : "SP"}
                    </Box>
                    {oddsicon(obj?.BookKeeperId, "header", data)}
                  </>
                ) : (
                  "SP"
                )}
              </TableCell>
            </>
          );
        })
      ) : (
        <TableCell>
          {" "}
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="no-odds">NOA</span>
          </Tooltip>
        </TableCell>
      );

    return Top5Odds;
  };
  const GetSponsoredOdds = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/sponsor?timeZone=${timezone}&SportId=${sportId}`
      );
      if (status === 200) {
        let providerIDs = [];
        let Ids = data?.result?.map((item) =>
          providerIDs?.push(item?.bookKeepersId)
        );
        setSponsoredId(providerIDs);
      } else {
        setSponsoredId([]);
      }
    } catch (err) {
      setSponsoredId([]);
    }
  };
  const fetchSponsoredOdds = (data, fulldata) => {
    let newOdds = data
      ?.filter((odds) => {
        return sponsoredId?.includes(odds.BookKeeperId);
      })
      ?.slice(0, 2);
    let firstSponsored = newOdds?.filter(
      (item) => item?.BookKeeperId === sponsoredId?.[0]
    );
    let secondSponsored = newOdds?.filter(
      (item) => item?.BookKeeperId === sponsoredId?.[1]
    );
    let SponsoredOdds =
      sponsoredId?.length > 0 ? (
        <>
          <Box>
            {firstSponsored?.length > 0 ? (
              <>
                <Box>
                  {fetchClickableOdds(
                    firstSponsored?.[0]?.RaceOdds?.[0]?.intValue !== 0
                      ? firstSponsored?.[0]?.RaceOdds?.[0]?.intValue
                      : "SP",
                    firstSponsored?.[0]?.BookKeeperId,
                    "sponsored",
                    data,
                    fulldata,
                    "betslip"
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box style={{ height: "22.88px" }}></Box>
              </>
            )}
            {oddsicon(sponsoredId?.[0], "sponsored", data)}
          </Box>
          {sponsoredId?.[1] ? (
            <Box>
              {secondSponsored?.length > 0 ? (
                <>
                  <Box>
                    {fetchClickableOdds(
                      secondSponsored?.[0]?.RaceOdds?.[0]?.intValue !== 0
                        ? secondSponsored?.[0]?.RaceOdds?.[0]?.intValue
                        : "SP",
                      secondSponsored?.[0]?.BookKeeperId,
                      "sponsored",
                      data,
                      fulldata,
                      "betslip"
                    )}
                  </Box>
                </>
              ) : (
                <>
                  <Box style={{ height: "22.88px" }}></Box>
                </>
              )}
              {oddsicon(sponsoredId?.[1], "sponsored", data)}
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <TableCell colSpan={2}>
          {" "}
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="no-odds">NOA</span>
          </Tooltip>
        </TableCell>
      );
    return SponsoredOdds;
  };
  const fetchSeeAllOddsvalue = (item, ProviderId) => {
    // let marketData = item?.oddsData?.[0]?.data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let datas = item?.oddsData?.[0]?.data
      ?.map((obj) => {
        if (obj?.BookKeeperId === ProviderId) {
          return {
            odds:
              obj?.RaceOdds?.[0]?.intValue !== 0
                ? obj?.RaceOdds?.[0]?.intValue
                : "SP",
            provider: obj?.BookKeeperId,
          };
        }
      })
      .filter((x) => x !== undefined);
    if (datas?.length > 0) {
      return fetchClickableOdds(
        datas?.[0]?.odds,
        datas?.[0]?.provider,
        "header",
        item?.oddsData?.[0]?.data,
        item,
        "betslip"
      );
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchMobileViewSeeAllOdds = (item, ProviderId) => {
    return (
      <>
        <TableCell className="next5odds-icon">
          <>
            <Box>{fetchSeeAllOddsvalue(item, ProviderId)}</Box>
            {oddsicon(ProviderId, "header", item?.oddsData?.[0]?.data)}
          </>
        </TableCell>
      </>
    );
  };

  const fetchMobileViewRunnerModal = (item) => {
    return (
      <>
        <Box className="runner-info-td">
          <Box className="runner-info-wrap">
            <Polyhon className="collapse-icon" />
            <Box className="runner-comment">
              <Box className="comment-content">
                <Typography variant="h6">
                  {localesData?.RUNNER_TABLE?.COLLAPSE?.RUNNER_COMMENTS}
                </Typography>
                <Typography variant="body2" className="des">
                  {item?.RunnerInfos?.[0]?.info?.entrant_comment
                    ? item?.RunnerInfos?.[0]?.info?.entrant_comment
                    : "No Comment Available"}
                </Typography>
              </Box>
              <Box className="full-from-btn">
                <Button onClick={() => handleOpen(runnerInfoData, item?.id)}>
                  {localesData?.RUNNER_TABLE?.COLLAPSE?.FULL_FORM}
                </Button>
              </Box>
              {/* <Box className="win-graph">
              <span className="graph-label lb first">
                Win
              </span>
              <span className="graph-label value second">
                12%
              </span>
              <span className="graph-label lb third">
                Place
              </span>
              <span className="graph-label value fourth">
                36%
              </span>
              <PieChart
                width={174}
                height={178}
                // onMouseEnter={this.onPieEnter}
              >
                <Pie
                  data={data}
                  innerRadius={70}
                  outerRadius={85}
                  paddingAngle={-10}
                  cornerRadius={20}
                  startAngle={440}
                  endAngle={80}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        COLORS[
                          index % COLORS.length
                        ]
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </Box> */}
            </Box>
            <Box className="advance-race-detail-wrap">
              <ul>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.SIRE}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.sire?.name
                      ? item?.RunnerInfos?.[0]?.info?.sire?.name
                      : "-"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.DAM}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.dam?.name
                      ? item?.RunnerInfos?.[0]?.info?.dam?.name
                      : "-"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.TYPE}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.runner_info?.colour
                      ? item?.RunnerInfos?.[0]?.info?.runner_info?.colour
                      : "-"}{" "}
                    /{" "}
                    {item?.RunnerInfos?.[0]?.info?.runner_info?.sex
                      ? item?.RunnerInfos?.[0]?.info?.runner_info?.sex
                      : "-"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.AGE}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.runner_info?.age
                      ? item?.RunnerInfos?.[0]?.info?.runner_info?.age + "yo"
                      : "-"}
                  </span>
                </li>

                <li>
                  <span>
                    {localesData?.RUNNER_TABLE?.COLLAPSE?.PRIZE_MONEY}:
                  </span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.runner_info?.prize_money
                      ? formatter.format(
                        item?.RunnerInfos?.[0]?.info?.runner_info?.prize_money
                      )
                      : "-"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.OVERALL}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                      ?.overall?.starts
                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.overall?.starts
                      : "0"}{" "}
                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                      ?.overall?.wins
                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.overall?.wins
                      : "0"}
                    -
                    {/* {runnerPastPerfomance?.overall
                    ?.placings
                    ? runnerPastPerfomance?.overall
                        ?.placings
                    : "0"}
                  - */}
                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                      ?.overall?.seconds
                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.overall?.seconds
                      : "0"}
                    -
                    {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                      ?.overall?.thirds
                      ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.overall?.thirds
                      : "0"}
                  </span>
                </li>
                <li>
                  <span>{localesData?.RUNNER_TABLE?.COLLAPSE?.LAST_SIX}:</span>
                  <span className="title">
                    {item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts
                      ? item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts?.slice(
                        item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts
                          ?.length - 6
                      )
                      : "-"}
                  </span>
                </li>
                <li></li>
              </ul>
            </Box>
            <Box className="runner-detail-tab-wrap">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Short Form" />
                <Tab label="Last Starts" />
              </Tabs>
              <TabPanel value={value} index={0} role="tabpanel">
                <Box className="short-form">
                  <ul>
                    <li>
                      <span>{localesData?.RUNNER_TABLE?.TRACK}:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.track?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.track?.wins
                          : "0"}
                        -
                        {/* {runnerPastPerfomance?.track
                        ?.placings
                        ? runnerPastPerfomance
                            ?.track?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.track?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.track?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>{localesData?.RUNNER_TABLE?.DISTANCE}:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.distance?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.distance?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.distance?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.distance?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.distance?.placings
                        ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.distance?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.distance?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.distance?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.distance?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.distance?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Trk/Dst:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track_distance?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.track_distance?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track_distance?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.track_distance?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.track_distance?.placings
                        ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.track_distance
                            ?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track_distance?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.track_distance
                            ?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.track_distance?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.track_distance?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>1st Up:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.first_up?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.first_up?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.first_up?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.first_up?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.first_up?.placings
                        ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.first_up?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.first_up?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.first_up?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.first_up?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.first_up?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>2nd Up:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.second_up?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.second_up?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.second_up?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.second_up?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.second_up?.placings
                        ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.second_up?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.second_up?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.second_up?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.second_up?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.second_up?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Firm:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.firm?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.firm?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.firm?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.firm?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                        ?.placings
                        ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                            ?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.firm?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.firm?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.firm?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.firm?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Good:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.good?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.good?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.good?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.good?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                        ?.placings
                        ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                            ?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.good?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.good?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.good?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.good?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Soft:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.soft?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.soft?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.soft?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.soft?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                        ?.placings
                        ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                            ?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.soft?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.soft?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.soft?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.soft?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Heavy:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.heavy?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.heavy?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.heavy?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.heavy?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                        ?.placings
                        ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.heavy?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.heavy?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.heavy?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.heavy?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.heavy?.thirds
                          : "0"}
                      </span>
                    </li>
                    <li>
                      <span>Synthetic:</span>
                      <span className="value">
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.synthetic?.starts
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.synthetic?.starts
                          : "0"}{" "}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.synthetic?.wins
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.synthetic?.wins
                          : "0"}
                        -
                        {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                        ?.synthetic?.placings
                        ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                            ?.synthetic?.placings
                        : "0"}
                      - */}
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.synthetic?.seconds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.synthetic?.seconds
                          : "0"}
                        -
                        {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                          ?.synthetic?.thirds
                          ? item?.RunnerInfos?.[0]?.info
                            ?.past_runner_performances?.synthetic?.thirds
                          : "0"}
                      </span>
                    </li>
                  </ul>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1} role="tabpanel">
                <Box className="runner-tab-table-head">
                  <Typography variant="h6"></Typography>
                  <Box className="table-nav">
                    <ChevronLeftIcon onClick={() => SlideTable("left")} />

                    <ChevronRightIcon onClick={() => SlideTable("right")} />
                  </Box>
                </Box>

                <TableContainer
                  id="runner-tab-table"
                  className="runner-tab-table"
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.PLACE}
                        </TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.DATE}
                        </TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.TRACK}
                        </TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.DISTANCE}
                        </TableCell>
                        <TableCell className="table-header">Jockey</TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.WEIGHT}
                        </TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.MARGIN}
                        </TableCell>
                        <TableCell className="table-header">SP</TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.CONDITION}
                        </TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.RESULT}
                        </TableCell>
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.CLASS}
                        </TableCell>
                        {/* <TableCell>Gate</TableCell> */}
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.RACE_TIME}
                        </TableCell>
                        {/* <TableCell>
                        Last 600m
                      </TableCell> */}
                        <TableCell className="table-header">
                          {localesData?.RUNNER_TABLE?.COLLAPSE?.PRIZE_MONEY}
                        </TableCell>
                        {/* <TableCell>
                        Run Comment
                      </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item?.RunnerInfos?.[0]?.info?.previous_runs?.length >
                        0 ? (
                        item?.RunnerInfos?.[0]?.info?.previous_runs?.map(
                          (item) => {
                            return (
                              <TableRow>
                                <TableCell className="table-cell" align="left">
                                  <span>
                                    {item?.finish && item?.number_of_runners
                                      ? item?.finish +
                                      "/" +
                                      item?.number_of_runners
                                      : "-"}
                                  </span>
                                </TableCell>
                                <TableCell className="table-cell" align="left">
                                  <span>{item?.date ? item?.date : "-"}</span>
                                </TableCell>
                                <TableCell className="table-cell" align="left">
                                  <span>{item?.track ? item?.track : "-"}</span>
                                </TableCell>
                                <TableCell className="table-cell" align="left">
                                  <span>
                                    {item?.distance
                                      ? item?.distance + "m"
                                      : "-"}
                                  </span>
                                </TableCell>
                                <TableCell className="table-cell" align="left">
                                  <span>
                                    {item?.jockey ? item?.jockey : "-"}
                                  </span>
                                </TableCell>
                                <TableCell className="table-cell" align="left">
                                  <span>
                                    {item?.weight_carried
                                      ? item?.weight_carried
                                      : "-"}
                                  </span>
                                </TableCell>
                                <TableCell className="table-cell" align="left">
                                  <span>
                                    {item?.margin ? item?.margin + "L" : "-"}
                                  </span>
                                </TableCell>
                                <TableCell className="table-cell" align="left">
                                  <span>
                                    {item?.starting_price
                                      ? "$" + item?.starting_price
                                      : "-"}
                                  </span>
                                </TableCell>
                                <TableCell className="table-cell" align="left">
                                  <span>
                                    {item?.track_condition
                                      ? item?.track_condition
                                      : "-"}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="result table-cell"
                                >
                                  {item?.winner ? (
                                    <span>
                                      {"1."} {item?.winner}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item?.second ? (
                                    <span className="disable">
                                      {"2."} {item?.second}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item?.third ? (
                                    <span className="disable">
                                      {"3."} {item?.third}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                <TableCell className="table-cell" align="left">
                                  <span>{item?.class ? item?.class : "-"}</span>
                                </TableCell>
                                {/* <TableCell align="left">
                              <span>{item?.gate ? item?.gate : "-"}</span>
                            </TableCell> */}
                                <TableCell className="table-cell" align="left">
                                  {item?.time ? item?.time : "-"}
                                </TableCell>
                                {/* <TableCell align="left">
                            {item?.last ? item?.last : "-"}
                            </TableCell> */}
                                <TableCell className="table-cell" align="left">
                                  {item?.prize_money
                                    ? formatter.format(item?.prize_money)
                                    : "-"}
                                </TableCell>
                                {/* <TableCell align="left">
                              <span>
                              {item?.comment ? item?.comment : "-"}
                              </span>
                            </TableCell> */}
                              </TableRow>
                            );
                          }
                        )
                      ) : (
                        <TableCell
                          className="table-cell"
                          align="center"
                          style={{
                            textAlign: "center",
                          }}
                          colSpan={100}
                        >
                          {localesData?.sport?.NO_DATA}
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  // const handleMouseEnter = (index) => {
  //   setTooltipOpen(true);
  //   setHoveredIndex(index)
  // };

  // const handleMouseLeave = () => {
  //   setTooltipOpen(false);
  // };

  const handleTooltipContentClick = (event, index) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(index);
  };
  const handleOutsideClick = (item, index) => {
    if (hoveredIndex === index) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };
  const handleOpenTooltip = (item, index) => {
    return (
      <Box
        className="custom-tooltip-runnertable"
        onClick={(e) => handleTooltipContentClick(e, index)}
      >
        {item?.animal?.isBlackBook &&
          item?.Jockey?.isBlackBook &&
          item?.Trainer?.isBlackBook ? (
          <BlackbookRight className="book-icon" />
        ) : (
          <BlackbookPlus className="book-icon" />
        )}
        {hoveredIndex === index ? (
          tooltipOpen && (
            <Box
              // className={sportId === "3" ? `tooltip greyhound-tooltip` : `tooltip`}
              className="tooltip runner-table-tooltip"
            >
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="add-blackbook">
                <AddBookIcon /> {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
              </Typography>
              <Box className="black-details">
                {item?.animal ? (
                  <Box
                    className="tooltip-details cursor-pointer"
                    onClick={() =>
                      handleBlackBookModal(
                        item?.animal?.name,
                        item?.animal?.id,
                        "Runner"
                      )
                    }
                  >
                    {" "}
                    <Typography className="details">
                      Runner: {item?.animal?.name}
                    </Typography>
                    {item?.animal?.isBlackBook > 0 ? (
                      <RightBlack className="icon-blackbook" />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                  </Box>
                ) : (
                  ""
                )}
                {item?.Jockey ? (
                  <Box
                    className="tooltip-details cursor-pointer"
                    onClick={() =>
                      handleBlackBookModal(
                        item?.Jockey?.name,
                        item?.Jockey?.id,
                        "Jockey"
                      )
                    }
                  >
                    {" "}
                    <Typography className="details">
                      {sportId === "2"
                        ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                        : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                      {item?.Jockey?.name}
                    </Typography>
                    {item?.Jockey?.isBlackBook > 0 ? (
                      <RightBlack className="icon-blackbook" />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                  </Box>
                ) : (
                  ""
                )}
                {item?.Trainer ? (
                  <Box
                    className="tooltip-details cursor-pointer"
                    onClick={() =>
                      handleBlackBookModal(
                        item?.Trainer?.name,
                        item?.Trainer?.id,
                        "Trainer"
                      )
                    }
                  >
                    {" "}
                    <Typography className="details">
                      Trainer: {item?.Trainer?.name}
                    </Typography>
                    {item?.Trainer?.isBlackBook > 0 ? (
                      <RightBlack className="icon-blackbook" />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}{" "}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const handleRunnerTabChange = (event, value) => {
    if (value === 1) {
      if (handleRestrictedUser()) {
        setRunnerTabValue(value);
      } else {
        dispatch(fetchRestrictedRoute(true));
        localStorage.setItem("prev_authroute", window.location.pathname);
      }
    } else {
      setRunnerTabValue(value);
    }
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };
  const handleSignupButtonClick = () => {
    navigate("/sign-up");
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  const handleOddsComparsion = (data) => {
    if (
      data?.isPremiumUser == true &&
      data?.subscription?.SubscriptionPlan?.key == "odds"
    ) {
      navigate(
        `/odds-comparison/${params?.race}/${params?.sportId}/${params?.trackId}/${params?.raceId}`
      );
    } else {
      navigate(`/soc-signup`);
    }
  };

  return (
    <>
      <Box className="race-player-detail-wrap side-space">
        <Box className="runner-tab">
          <Tabs
            value={runnerTabValue}
            variant="scrollable"
            scrollButtons={false}
            indicatorColor="primary"
            textColor="primary"
            className="runner-tab-detail"
            disableRipple
            disableFocusRipple
          >
            {runnerTabData?.map((item, index) => {
              return (
                <Box key={index}>
                  <Tab
                    disableRipple
                    disableFocusRipple
                    label={
                      <Box className="tab-label">
                        <span className="label-name">{item?.name}</span>
                        <span className="label-icon">{item?.icon}</span>
                      </Box>
                    }
                    value={item?.id}
                    className={item?.id === runnerTabValue ? "active " : ""}
                    onChange={(event, newValue) =>
                      handleRunnerTabChange(event, item?.id)
                    }
                  />
                </Box>
              );
            })}
          </Tabs>
        </Box>
      </Box>
      {runnerTabValue === 0 ? (
        <Box className="race-player-detail-wrap side-space">
          <Box className="runner-detail-wrap">
            <Box className="odds-table-head">
              <Box
                className={`${!Boolean(handleRestrictedUser()) ? "sign-up-dropdown-wrap" : "dropdown-subscribe-wrap"}`}
              >
                <Box className="select-wrap">
                  <Select
                    className="React series-select"
                    value={options?.find((item) => {
                      return item?.value == selectedOption;
                    })}
                    onChange={(e) => oddsTypeChange(e)}
                    options={options}
                    isOptionDisabled={(option) => option.disabled}
                    classNamePrefix="select"
                    isSearchable={false}
                    components={{ DropdownIndicator }}
                  />
                </Box>
                <Box
                  className={`${!Boolean(handleRestrictedUser()) ? "sign-up-btn-details " : "subscription-btn-detail"}`}
                >
                  {!Boolean(handleRestrictedUser()) && (
                    <Typography className="subs-des">
                      Sign up to see{" "}
                      <span className="color-primary">Current Best Odds</span>{" "}
                      from over 50 bookmakers.
                    </Typography>
                  )}
                  {Boolean(handleRestrictedUser()) ? (
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="runnertable-btn"
                      onClick={() => {
                        handleOddsComparsion(userData);
                      }}
                    >
                      {userData?.isPremiumUser &&
                        userData?.subscription?.SubscriptionPlan?.key == "odds"
                        ? " Smart Odds Comparison"
                        : " Subscribe for Smart Odds Comparison"}

                      {/* {userData?.isPremiumUser ? " Smart Odds Comparison" : " Subscribe for Smart Odds Comparison"} */}
                    </Button>
                  ) : (
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="runnertable-btn"
                      onClick={() => {
                        handleSignupButtonClick();
                      }}
                    >
                      Sign up Now!
                    </Button>
                  )}
                </Box>
              </Box>
              {/* <Box className="disclaimer"> *{localesData?.RUNNER_TABLE?.DISCLAIMER_TEXT}</Box> */}
            </Box>
            {/* <span>
      {options?.filter((item) => {
        return item?.value === selectedOption ? item?.label : " ";
      })}
    </span> */}
            {screenWidth > 799 ? (
              seeall != true ? (
                <TableContainer className="race-bookmakersodd">
                  {isrunnnerLoading || oddsLoader ? (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  ) : (
                    <Table
                      className="racing-data featured-table"
                      aria-label="customized table"
                      style={{ minWidth: 700, borderRadius: 5 }}
                    >
                      <TableHead className="racing-track-table-head">
                        <TableRow style={{ fontSize: 12 }} className="head-row">
                          <TableCell
                            className="track-table-th table-left-header"
                            style={{ textAlign: "left" }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                columnGap: "18px",
                              }}
                            >
                              <span>
                                {localesData?.RUNNER_TABLE?.RUNNER}
                                <Order
                                  className="player-order"
                                  onClick={handleRunnerOrder}
                                />
                              </span>
                              <span
                                className="ex-full"
                                onClick={() => handleExpandfullForm()}
                              >
                                {localesData?.RUNNER_TABLE?.EXPAND_FULL_FORM}
                              </span>
                            </Box>
                          </TableCell>
                          {/* <TableCell className="track-tabel-odds-th table-head" style={{ width: "180px", textAlign: "center" }}>
                      {localesData?.RUNNER_TABLE?.BEST_AT_OPEN}
                    </TableCell> */}
                          <TableCell
                            className="track-tabel-odds-th table-head current-best"
                            style={{ width: "134px", textAlign: "center" }}
                          >
                            {!isResult
                              ? localesData?.RUNNER_TABLE?.CURRENT_BEST
                              : localesData?.RUNNER_TABLE?.BEST_AT_CLOSE}
                          </TableCell>
                          <TableCell
                            className="track-tabel-odds-th table-head sponsor-odds-header"
                            style={{ width: "554px", textAlign: "center" }}
                          >
                            Featured bookmaker Odds
                          </TableCell>
                          {sponsoredId?.length > 0 ? (
                            <TableCell
                              colSpan={2}
                              className="track-tabel-odds-th table-head sponsored-header"
                              style={{ width: "180px", textAlign: "center" }}
                            >
                              <span className="sponsored">
                                {localesData?.sport?.SPONSORED}
                              </span>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {runnerInfoData?.length > 0 ? (
                          <>
                            {runnerInfoData?.map((item, index) => {
                              return (
                                <>
                                  {item?.isScratched === "false" ||
                                    item?.isScratched === null ||
                                    item?.isScratched === "0" ? (
                                    <TableRow
                                      //   key={index}
                                      //   className={
                                      //     openRaceRow === index
                                      //       ? "racing-track-data-row rctdrbb-none"
                                      //       : runnersWithOdd?.length - 1 === index
                                      //       ? "racing-track-data-row last-to2ndrow"
                                      //       : "racing-track-data-row"
                                      //   }
                                      className="racing-track-data-row"
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        className="border-right"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Box className="table-runner-info">
                                          <Box
                                            className="collapse-icon"
                                            onClick={() => {
                                              setOpen(
                                                open === index ? -1 : index
                                              );
                                            }}
                                          >
                                            {open === index ||
                                              isFullFormExpand ? (
                                              <CollepseOpen />
                                            ) : (
                                              <CollepseClose />
                                            )}
                                          </Box>
                                          <Box
                                            className="racer-detail-wrap"
                                          // onClick={() => {
                                          //     setOpen(open === index ? -1 : index);

                                          // }}
                                          >
                                            <ClickAwayListener
                                              onClickAway={() =>
                                                handleOutsideClick(item, index)
                                              }
                                            >
                                              <Box className="blackbook-icon">
                                                <Typography
                                                  variant="h6"
                                                  onClick={() => {
                                                    setOpen(
                                                      open === index
                                                        ? -1
                                                        : index
                                                    );
                                                  }}
                                                  className="cursor-pointer"
                                                >
                                                  {item?.runnerNumber}
                                                  {"."} {item?.animal?.name} (
                                                  {item?.barrierNumber})
                                                </Typography>
                                                {handleOpenTooltip(item, index)}
                                              </Box>
                                            </ClickAwayListener>
                                            <Box className="player-wrap">
                                              <Box className="player-detail">
                                                {item?.Jockey ? (
                                                  <>
                                                    <span className="text-semibold">
                                                      {sportId === "2"
                                                        ? `D: `
                                                        : `J: `}
                                                    </span>
                                                    <span>
                                                      {item?.Jockey?.name}
                                                    </span>
                                                  </>
                                                ) : item?.Trainer ? (
                                                  <>
                                                    <span className="text-semibold">
                                                      T:{" "}
                                                    </span>
                                                    <span>
                                                      {item?.Trainer?.name}
                                                    </span>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Box>
                                              {sportId === "1" ? (
                                                <Box className="player-detail">
                                                  <span className="text-semibold">
                                                    W:{" "}
                                                  </span>
                                                  <span>
                                                    {Number(
                                                      item?.JockeyWeight
                                                    ).toFixed(2) + "Kg"}
                                                  </span>
                                                </Box>
                                              ) : (
                                                ""
                                              )}
                                            </Box>
                                            <Box className="player-wrap">
                                              {/* <Box className="player-detail">
                                <span>Form:</span>
                                <span> {}</span>
                              </Box> */}
                                              {sportId === "1" ||
                                                sportId === "2" ? (
                                                <Box className="player-detail">
                                                  {item?.Trainer ? (
                                                    <>
                                                      <span className="text-semibold">
                                                        T:{" "}
                                                      </span>
                                                      <span>
                                                        {item?.Trainer?.name}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Box>
                                              ) : (
                                                ""
                                              )}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </TableCell>
                                      {/* <TableCell className="border-right odds-column">
                                  {item?.oddsData?.[0]?.data ? (
                                    <>
                                      <Box>
                                        {fetchBestOpenValue(item?.oddsData?.[0]?.data, 0)}
                                      </Box>
                                      {fetchBestOpenIcon(item?.oddsData?.[0]?.data, 0)}
                                    </>
                                  ) : (
                                    <Tooltip title="No odds available" className="odds-tooltip" placement="top">
                                      <span className="no-odds">NOA</span>
                                    </Tooltip>
                                  )}
                                </TableCell> */}
                                      <TableCell className="border-right">
                                        {item?.oddsData?.[0]?.data ? (
                                          <Box
                                            className={`current-best-odds-wrap ${localAuth ? "" : "blur-comp"}`}
                                          >
                                            {fetchCurrentBestValue(
                                              item?.oddsData?.[0]?.data,
                                              item
                                            )}
                                            {/* </Box> */}
                                            {/* <Box className="current-best-odds-icon"> */}
                                            {fetchCurrentBestIcon(
                                              item?.oddsData?.[0]?.data
                                            )}
                                            {/* </Box> */}
                                          </Box>
                                        ) : (
                                          <Tooltip
                                            title="No odds available"
                                            className="odds-tooltip"
                                            placement="top"
                                          >
                                            <span className="no-odds">NOA</span>
                                          </Tooltip>
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {!isLoading &&
                                          topBookMakersData?.length === 0 && (
                                            <p style={{ textAlign: "center" }}>
                                              No Data Available
                                            </p>
                                          )}
                                        {!isLoading &&
                                          topBookMakersData?.length > 0 && (
                                            <Box className="hide-scroll max-w">
                                              <Box className="odds-flex">
                                                {topBookMakersData
                                                  ?.slice(0, 6)
                                                  ?.map((bookkeper, index) => {
                                                    return fetchFeaturedOdds(
                                                      item?.oddsData?.[0]?.data,
                                                      bookkeper?.id,
                                                      item
                                                    );
                                                    // return (
                                                    //     <Box className="odds-box-style">
                                                    //         <Box className="odds-style">
                                                    //             <Typography className="odds">2.00</Typography>
                                                    //         </Box>
                                                    //         <Box className="odd-img">
                                                    //             <img
                                                    //                 src={
                                                    //                     item?.long_logo
                                                    //                         ? Config.mediaURL + item?.long_logo
                                                    //                         : DefaultImg
                                                    //                 }
                                                    //                 alt="bk"
                                                    //             />
                                                    //         </Box>
                                                    //     </Box>
                                                    // );
                                                  })}
                                              </Box>
                                            </Box>
                                          )}
                                      </TableCell>
                                      {sponsoredId?.length > 0 ? (
                                        <>
                                          <TableCell
                                            colSpan={2}
                                            className="next5odds-icon border-right odds-column"
                                          >
                                            <Box className="sponsored-odds">
                                              {fetchSponsoredOdds(
                                                item?.oddsData?.[0]?.data,
                                                item
                                              )}
                                            </Box>
                                          </TableCell>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {/* {item?.oddsData?.[0]?.data ? (
                              <>
                                {fetchTop5Odds(item?.oddsData?.[0]?.data, isResult)}
                              </>
                            ) : (<TableCell>  <Tooltip title="No odds available" className="odds-tooltip" placement="top">
                              <span className="no-odds">NOA</span>
                            </Tooltip> </TableCell>)} */}
                                      <TableCell className="border-left border-bottom"></TableCell>
                                      {/* {item?.oddsData?.[0]?.data ? (item?.oddsData?.[0]?.data?.map((obj) => {
                              return (
                                <>
                                  <TableCell>
                                    {obj?.RaceOdds?.[0]?.intValue || obj?.RaceOdds?.[0]?.intValue === 0 ? (
                                      <>
                                        <Box>
                                          {obj?.RaceOdds?.[0]?.intValue}
                                        </Box>
                                        {oddsicon(obj?.BookKeeperId)}
                                      </>
                                    ) : ("-")}
                                  </TableCell>
                                </>
                              )
                            })) : (<TableCell> - </TableCell>)} */}
                                    </TableRow>
                                  ) : (
                                    <TableRow className="racing-track-data-row">
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        className="racing-data"
                                        colSpan={
                                          sponsoredId?.length > 0 ? 5 : 3
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Box
                                          className="racer-detail-wrap"
                                          onClick={() => {
                                            setOpen(
                                              open === index ? -1 : index
                                            );
                                            // if (open === -1 || open !== index)
                                            //   fetchRunnerExtraInfor(item?.id);
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            onClick={() => {
                                              setOpen(
                                                open === index ? -1 : index
                                              );
                                            }}
                                          >
                                            <Box className="scratched">
                                              <span className="scratched-runner-name cursor-pointer">
                                                {item?.runnerNumber}
                                                {"."} {item?.animal?.name} (
                                                {item?.barrierNumber})
                                              </span>
                                            </Box>
                                            <Box className="scratched-data">
                                              <span className="scratched-runner-text">
                                                {
                                                  localesData?.RUNNER_TABLE
                                                    ?.SCRATCHED
                                                }{" "}
                                              </span>{" "}
                                              @{" "}
                                              <span className="scratched-runner-time">
                                                {" "}
                                                {moment(item?.updatedAt).format(
                                                  "hh:mm A"
                                                )}
                                              </span>
                                              <span className="scratched-runner-date">
                                                {" "}
                                                {moment(item?.updatedAt).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </span>
                                            </Box>
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell className="border-left border-bottom"></TableCell>
                                    </TableRow>
                                  )}
                                  {/* {BetSlipToggleData ? <></> :  */}
                                  <TableRow
                                    style={{
                                      borderBottom: "none",
                                    }}
                                    className="runner-info-tr"
                                  >
                                    <TableCell
                                      colSpan={100}
                                      className="runner-info-td"
                                      style={{
                                        padding: "0 !important",
                                        borderBottom: "none",
                                      }}
                                    >
                                      {!raceParticipantData && (
                                        <Collapse
                                          in={
                                            isFullFormExpand
                                              ? isFullFormExpand
                                              : open === index
                                          }
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <>
                                            <Box className="runner-info-wrap">
                                              <Polyhon className="collapse-icon" />
                                              <Box className="runner-comment">
                                                <Box className="comment-content">
                                                  <Typography variant="h6">
                                                    {
                                                      localesData?.RUNNER_TABLE
                                                        ?.COLLAPSE
                                                        ?.RUNNER_COMMENTS
                                                    }
                                                  </Typography>
                                                  <Typography variant="body2">
                                                    {item?.RunnerInfos?.[0]
                                                      ?.info?.entrant_comment
                                                      ? item?.RunnerInfos?.[0]
                                                        ?.info
                                                        ?.entrant_comment
                                                      : localesData
                                                        ?.RUNNER_TABLE
                                                        ?.COLLAPSE
                                                        ?.NO_COMMENTS}
                                                  </Typography>
                                                </Box>
                                                <Box className="full-from-btn">
                                                  <Button
                                                    onClick={() =>
                                                      handleOpen(
                                                        runnerInfoData,
                                                        item?.id
                                                      )
                                                    }
                                                  >
                                                    {" "}
                                                    {
                                                      localesData?.RUNNER_TABLE
                                                        ?.COLLAPSE?.FULL_FORM
                                                    }
                                                  </Button>
                                                </Box>
                                                {/* <Box className="win-graph">
                                      <span className="graph-label lb first">
                                        Win
                                      </span>
                                      <span className="graph-label value second">
                                        12%
                                      </span>
                                      <span className="graph-label lb third">
                                        Place
                                      </span>
                                      <span className="graph-label value fourth">
                                        36%
                                      </span>
                                      <PieChart
                                        width={174}
                                        height={178}
                                        // onMouseEnter={this.onPieEnter}
                                      >
                                        <Pie
                                          data={data}
                                          innerRadius={70}
                                          outerRadius={85}
                                          paddingAngle={-10}
                                          cornerRadius={20}
                                          startAngle={440}
                                          endAngle={80}
                                          dataKey="value"
                                        >
                                          {data.map((entry, index) => (
                                            <Cell
                                              key={`cell-${index}`}
                                              fill={
                                                COLORS[
                                                  index % COLORS.length
                                                ]
                                              }
                                            />
                                          ))}
                                        </Pie>
                                      </PieChart>
                                    </Box> */}
                                              </Box>
                                              <Box className="advance-race-detail-wrap">
                                                <ul>
                                                  <li>
                                                    <span>
                                                      {
                                                        localesData
                                                          ?.RUNNER_TABLE
                                                          ?.COLLAPSE?.SIRE
                                                      }
                                                      :
                                                    </span>
                                                    <span className="title">
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info?.sire?.name
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info?.sire?.name
                                                        : "-"}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <span>
                                                      {
                                                        localesData
                                                          ?.RUNNER_TABLE
                                                          ?.COLLAPSE?.DAM
                                                      }
                                                      :
                                                    </span>
                                                    <span className="title">
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info?.dam?.name
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info?.dam?.name
                                                        : "-"}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <span>
                                                      {
                                                        localesData
                                                          ?.RUNNER_TABLE
                                                          ?.COLLAPSE?.TYPE
                                                      }
                                                      :
                                                    </span>
                                                    <span className="title">
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info?.runner_info
                                                        ?.colour
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info?.runner_info
                                                          ?.colour
                                                        : "-"}{" "}
                                                      /{" "}
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info?.runner_info?.sex
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info?.runner_info
                                                          ?.sex
                                                        : "-"}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <span>
                                                      {
                                                        localesData
                                                          ?.RUNNER_TABLE
                                                          ?.COLLAPSE?.AGE
                                                      }
                                                      :
                                                    </span>
                                                    <span className="title">
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info?.runner_info?.age
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info?.runner_info
                                                          ?.age + "yo"
                                                        : "-"}
                                                    </span>
                                                  </li>
                                                  {/* </ul>
                                                                                        <ul> */}
                                                  <li>
                                                    <span>
                                                      {
                                                        localesData
                                                          ?.RUNNER_TABLE
                                                          ?.COLLAPSE
                                                          ?.PRIZE_MONEY
                                                      }
                                                      :
                                                    </span>
                                                    <span className="title">
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info?.runner_info
                                                        ?.prize_money
                                                        ? formatter.format(
                                                          item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.runner_info
                                                            ?.prize_money
                                                        )
                                                        : "-"}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <span>
                                                      {
                                                        localesData
                                                          ?.RUNNER_TABLE
                                                          ?.COLLAPSE?.OVERALL
                                                      }
                                                      :
                                                    </span>
                                                    <span className="title">
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info
                                                        ?.past_runner_performances
                                                        ?.overall?.starts
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info
                                                          ?.past_runner_performances
                                                          ?.overall?.starts
                                                        : "0"}{" "}
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info
                                                        ?.past_runner_performances
                                                        ?.overall?.wins
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info
                                                          ?.past_runner_performances
                                                          ?.overall?.wins
                                                        : "0"}
                                                      -
                                                      {/* {runnerPastPerfomance?.overall
                                            ?.placings
                                            ? runnerPastPerfomance?.overall
                                                ?.placings
                                            : "0"}
                                          - */}
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info
                                                        ?.past_runner_performances
                                                        ?.overall?.seconds
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info
                                                          ?.past_runner_performances
                                                          ?.overall?.seconds
                                                        : "0"}
                                                      -
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info
                                                        ?.past_runner_performances
                                                        ?.overall?.thirds
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info
                                                          ?.past_runner_performances
                                                          ?.overall?.thirds
                                                        : "0"}
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <span>
                                                      Last{" "}
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info?.runner_info
                                                        ?.last_starts
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info?.runner_info
                                                          ?.last_starts
                                                          ?.length > 5
                                                          ? 6
                                                          : item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.runner_info
                                                            ?.last_starts
                                                            ?.length
                                                        : 6}
                                                      :
                                                    </span>
                                                    <span className="title">
                                                      {item?.RunnerInfos?.[0]
                                                        ?.info?.runner_info
                                                        ?.last_starts
                                                        ? item?.RunnerInfos?.[0]
                                                          ?.info?.runner_info
                                                          ?.last_starts
                                                          ?.length > 5
                                                          ? item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts?.slice(
                                                            item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.runner_info
                                                              ?.last_starts
                                                              ?.length - 6
                                                          )
                                                          : item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.runner_info
                                                            ?.last_starts
                                                        : "-"}
                                                    </span>
                                                  </li>
                                                  {/* <li></li> */}
                                                </ul>
                                              </Box>
                                              <Box className="runner-detail-tab-wrap">
                                                <Tabs
                                                  value={value}
                                                  onChange={handleChange}
                                                  aria-label="simple tabs example"
                                                >
                                                  <Tab label="Short Form" />
                                                  <Tab label="Last Starts" />
                                                </Tabs>
                                                <TabPanel
                                                  value={value}
                                                  index={0}
                                                  role="tabpanel"
                                                >
                                                  <Box className="short-form">
                                                    <ul>
                                                      <li>
                                                        <span>
                                                          {
                                                            localesData
                                                              ?.RUNNER_TABLE
                                                              ?.TRACK
                                                          }
                                                          :
                                                        </span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.track?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.track?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.track?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.track?.wins
                                                            : "0"}
                                                          -
                                                          {/* {runnerPastPerfomance?.track
                                                ?.placings
                                                ? runnerPastPerfomance
                                                    ?.track?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.track?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.track?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.track?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.track?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <span>
                                                          {
                                                            localesData
                                                              ?.RUNNER_TABLE
                                                              ?.DISTANCE
                                                          }
                                                          :
                                                        </span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.distance?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.distance
                                                              ?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.distance?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.distance?.wins
                                                            : "0"}
                                                          -
                                                          {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.distance?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.distance?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.distance?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.distance
                                                              ?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.distance?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.distance
                                                              ?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <span>Trk/Dst:</span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.track_distance
                                                            ?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.track_distance
                                                              ?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.track_distance
                                                            ?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.track_distance
                                                              ?.wins
                                                            : "0"}
                                                          -
                                                          {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.track_distance?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.track_distance
                                                    ?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.track_distance
                                                            ?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.track_distance
                                                              ?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.track_distance
                                                            ?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.track_distance
                                                              ?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <span>1st Up:</span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.first_up?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.first_up
                                                              ?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.first_up?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.first_up?.wins
                                                            : "0"}
                                                          -
                                                          {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.first_up?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.first_up?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.first_up?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.first_up
                                                              ?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.first_up?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.first_up
                                                              ?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <span>2nd Up:</span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.second_up?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.second_up
                                                              ?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.second_up?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.second_up
                                                              ?.wins
                                                            : "0"}
                                                          -
                                                          {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.second_up?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.second_up?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.second_up?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.second_up
                                                              ?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.second_up?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.second_up
                                                              ?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <span>Firm:</span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.firm?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.firm?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.firm?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.firm?.wins
                                                            : "0"}
                                                          -
                                                          {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                ?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                    ?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.firm?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.firm?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.firm?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.firm?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <span>Good:</span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.good?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.good?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.good?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.good?.wins
                                                            : "0"}
                                                          -
                                                          {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                ?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                    ?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.good?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.good?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.good?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.good?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <span>Soft:</span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.soft?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.soft?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.soft?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.soft?.wins
                                                            : "0"}
                                                          -
                                                          {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                ?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                    ?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.soft?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.soft?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.soft?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.soft?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <span>Heavy:</span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.heavy?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.heavy?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.heavy?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.heavy?.wins
                                                            : "0"}
                                                          -
                                                          {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                                                ?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.heavy?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.heavy?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.heavy?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.heavy?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.heavy?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <span>Synthetic:</span>
                                                        <span className="value">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.synthetic?.starts
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.synthetic
                                                              ?.starts
                                                            : "0"}{" "}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.synthetic?.wins
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.synthetic
                                                              ?.wins
                                                            : "0"}
                                                          -
                                                          {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.synthetic?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.synthetic?.placings
                                                : "0"}
                                              - */}
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.synthetic?.seconds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.synthetic
                                                              ?.seconds
                                                            : "0"}
                                                          -
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.past_runner_performances
                                                            ?.synthetic?.thirds
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.synthetic
                                                              ?.thirds
                                                            : "0"}
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </Box>
                                                </TabPanel>
                                                <TabPanel
                                                  value={value}
                                                  index={1}
                                                  role="tabpanel"
                                                >
                                                  <Box className="runner-tab-table-head">
                                                    <Typography variant="h6"></Typography>
                                                    <Box className="table-nav">
                                                      <ChevronLeftIcon
                                                        onClick={() =>
                                                          SlideTable("left")
                                                        }
                                                      />

                                                      <ChevronRightIcon
                                                        onClick={() =>
                                                          SlideTable("right")
                                                        }
                                                      />
                                                    </Box>
                                                  </Box>

                                                  <TableContainer
                                                    id="runner-tab-table"
                                                    className="runner-tab-table"
                                                  >
                                                    <Table>
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.PLACE
                                                            }
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE?.DATE
                                                            }
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.TRACK
                                                            }
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.DISTANCE
                                                            }
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            Jockey
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.WEIGHT
                                                            }
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.MARGIN
                                                            }
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            SP
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.CONDITION
                                                            }
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.RESULT
                                                            }
                                                          </TableCell>
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.CLASS
                                                            }
                                                          </TableCell>
                                                          {/* <TableCell>Gate</TableCell> */}
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.RACE_TIME
                                                            }{" "}
                                                          </TableCell>
                                                          {/* <TableCell>
                                                Last 600m
                                              </TableCell> */}
                                                          <TableCell className="table-header">
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.PRIZE_MONEY
                                                            }
                                                          </TableCell>
                                                          {/* <TableCell>
                                                Run Comment
                                              </TableCell> */}
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        {item?.RunnerInfos?.[0]
                                                          ?.info?.previous_runs
                                                          ?.length > 0 ? (
                                                          item?.RunnerInfos?.[0]?.info?.previous_runs?.map(
                                                            (item) => {
                                                              return (
                                                                <TableRow>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.finish &&
                                                                        item?.number_of_runners
                                                                        ? item?.finish +
                                                                        "/" +
                                                                        item?.number_of_runners
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.date
                                                                        ? item?.date
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.track
                                                                        ? item?.track
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.distance
                                                                        ? item?.distance +
                                                                        "m"
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.jockey
                                                                        ? item?.jockey
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.weight_carried
                                                                        ? item?.weight_carried
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.margin
                                                                        ? item?.margin +
                                                                        "L"
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.starting_price
                                                                        ? "$" +
                                                                        item?.starting_price
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.track_condition
                                                                        ? item?.track_condition
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    align="left"
                                                                    className="result table-cell"
                                                                  >
                                                                    {item?.winner ? (
                                                                      <span>
                                                                        {"1."}{" "}
                                                                        {
                                                                          item?.winner
                                                                        }
                                                                      </span>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    {item?.second ? (
                                                                      <span className="disable">
                                                                        {"2."}{" "}
                                                                        {
                                                                          item?.second
                                                                        }
                                                                      </span>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    {item?.third ? (
                                                                      <span className="disable">
                                                                        {"3."}{" "}
                                                                        {
                                                                          item?.third
                                                                        }
                                                                      </span>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </TableCell>
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    <span>
                                                                      {item?.class
                                                                        ? item?.class
                                                                        : "-"}
                                                                    </span>
                                                                  </TableCell>
                                                                  {/* <TableCell align="left">
                                                      <span>{item?.gate ? item?.gate : "-"}</span>
                                                    </TableCell> */}
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    {item?.time
                                                                      ? item?.time
                                                                      : "-"}
                                                                  </TableCell>
                                                                  {/* <TableCell align="left">
                                                    {item?.last ? item?.last : "-"}
                                                    </TableCell> */}
                                                                  <TableCell
                                                                    className="table-cell"
                                                                    align="left"
                                                                  >
                                                                    {item?.prize_money
                                                                      ? formatter.format(
                                                                        item?.prize_money
                                                                      )
                                                                      : "-"}
                                                                  </TableCell>
                                                                  {/* <TableCell align="left">
                                                      <span>
                                                      {item?.comment ? item?.comment : "-"}
                                                      </span>
                                                    </TableCell> */}
                                                                </TableRow>
                                                              );
                                                            }
                                                          )
                                                        ) : (
                                                          <TableCell
                                                            className="table-cell"
                                                            align="center"
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                            colSpan={100}
                                                          >
                                                            {
                                                              localesData?.sport
                                                                ?.NO_DATA
                                                            }
                                                          </TableCell>
                                                        )}
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                </TabPanel>
                                              </Box>
                                            </Box>
                                          </>
                                        </Collapse>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  {/* } */}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <TableRow style={{ border: "transparent" }}>
                            <TableCell
                              style={{
                                textAlign: "center",
                                border: "transparent",
                              }}
                              colSpan={21}
                            >
                              {/* {localesData?.sport?.NO_DATA} */}
                              <NoDataComp />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              ) : (
                <>
                  <span
                    className="ex-full see-filter see-best"
                    onClick={handleChangeTable}
                  >
                    {localesData?.RUNNER_TABLE?.SEE_BEST}
                  </span>
                  <TableContainer
                    className="sticky-race-bookmakersodd"
                    id="sticky-race-bookmakersodd"
                    style={{ overflowX: overFlow ? "auto " : "hidden" }}
                  >
                    {overFlow ? <Box className="overlay"></Box> : ""}
                    {openSeall == -1 && isSeeallFullFormExpand === false ? (
                      <Box className="arrow-wrap">
                        <span
                          className={scrollX == "right" ? "arrow" : "disable"}
                          onClick={() => SlideSeeAllTable("left")}
                        >
                          <ChevronLeftIcon />
                        </span>

                        <span
                          className={scrollX == "left" ? "arrow" : "disable"}
                          onClick={() => SlideSeeAllTable("right")}
                        >
                          <ChevronRightIcon />
                        </span>
                      </Box>
                    ) : (
                      ""
                    )}
                    {isrunnnerLoading || oddsLoader ? (
                      <div className="allsport-loader-center">
                        <Loader />
                      </div>
                    ) : (
                      <Table
                        stickyHeader
                        aria-label="sticky table "
                        className="sticky-racing-data"
                      >
                        <TableHead>
                          <TableRow>
                            <StickyTableCell className={classes.head}>
                              <TableCell className={classes.head} numeric>
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    columnGap: "18px",
                                  }}
                                >
                                  <span>
                                    {localesData?.RUNNER_TABLE?.RUNNER}
                                    <Order
                                      className="player-order"
                                      onClick={handleRunnerOrder}
                                    />
                                  </span>
                                  <span
                                    className="ex-full"
                                    onClick={() => handleSeeallExpandfullForm()}
                                  >
                                    {
                                      localesData?.RUNNER_TABLE
                                        ?.EXPAND_FULL_FORM
                                    }
                                  </span>
                                </Box>
                              </TableCell>
                            </StickyTableCell>
                            {pageHeadingData?.length > 0 ? (
                              pageHeadingData?.map((id) => (
                                <TableCell className={classes.head} numeric>
                                  {oddsicon(id?.BookKeeperId, "header")}
                                </TableCell>
                              ))
                            ) : (
                              <TableCell></TableCell>
                            )}

                            <TableCell
                              className={classes.head}
                              numeric
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {runnerInfoData?.length > 0 ? (
                            <>
                              {runnerInfoData?.map((item, index) => {
                                return (
                                  <>
                                    {item?.isScratched === "false" ||
                                      item?.isScratched === null ||
                                      item?.isScratched == "0" ? (
                                      <TableRow>
                                        <StickyTableCell>
                                          <TableCell
                                            numeric
                                            align="right"
                                            className={classes.cell}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <Box
                                              className="racer-detail-wrap"
                                              onClick={() => {
                                                handleTableOpen("isColleps");
                                                setopenSeall(
                                                  openSeall === index
                                                    ? -1
                                                    : index
                                                );
                                                // if (openSeall === -1 || openSeall !== index) {
                                                //   fetchRunnerExtraInfor(item?.id);
                                                // }
                                                if (
                                                  openSeall === -1 ||
                                                  openSeall !== index
                                                ) {
                                                  setOverFLow(false);
                                                } else {
                                                  setOverFLow(true);
                                                }
                                              }}
                                            >
                                              <Typography
                                                variant="h6"
                                                onClick={() => {
                                                  setOpen(
                                                    open === index ? -1 : index
                                                  );
                                                }}
                                                className="cursor-pointer"
                                              >
                                                {item?.runnerNumber}
                                                {"."} {item?.animal?.name} (
                                                {item?.barrierNumber})
                                              </Typography>
                                              <Box className="player-wrap">
                                                {sportId === "1" ? (
                                                  <Box className="player-detail">
                                                    <span>
                                                      {
                                                        localesData
                                                          ?.RUNNER_TABLE?.WEIGHT
                                                      }
                                                      :
                                                    </span>
                                                    <span>
                                                      {" "}
                                                      {Number(
                                                        item?.JockeyWeight
                                                      ).toFixed(2) + "Kg"}
                                                    </span>
                                                  </Box>
                                                ) : (
                                                  ""
                                                )}
                                                <Box className="player-detail">
                                                  {item?.Jockey ? (
                                                    <>
                                                      <span>
                                                        {sportId === "2"
                                                          ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                                                          : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                                                      </span>
                                                      <span>
                                                        {item?.Jockey?.name}
                                                      </span>
                                                    </>
                                                  ) : item?.Trainer ? (
                                                    <>
                                                      <span>
                                                        {
                                                          localesData
                                                            ?.RUNNER_TABLE
                                                            ?.TRAINER
                                                        }
                                                        :{" "}
                                                      </span>
                                                      <span>
                                                        {item?.Trainer?.name}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Box>
                                              </Box>
                                              <Box className="player-wrap">
                                                {/* <Box className="player-detail">
                                <span>Form:</span>
                                <span> {}</span>
                              </Box> */}
                                                {sportId === "1" ||
                                                  sportId === "2" ? (
                                                  <Box className="player-detail">
                                                    {item?.Trainer ? (
                                                      <>
                                                        <span>
                                                          {
                                                            localesData
                                                              ?.RUNNER_TABLE
                                                              ?.TRAINER
                                                          }
                                                          :{" "}
                                                        </span>
                                                        <span>
                                                          {item?.Trainer?.name}
                                                        </span>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Box>
                                                ) : (
                                                  ""
                                                )}
                                              </Box>
                                            </Box>
                                          </TableCell>
                                        </StickyTableCell>
                                        <>
                                          {pageHeadingData?.length > 0 ? (
                                            pageHeadingData?.map((id) => (
                                              <TableCell
                                                numeric
                                                align="center"
                                                className={classes.cell}
                                              >
                                                {fetchSeeAllOddsvalue(
                                                  item,
                                                  id?.BookKeeperId
                                                )}
                                              </TableCell>
                                            ))
                                          ) : (
                                            <TableCell
                                              style={{ textAlign: "center" }}
                                            >
                                              <Tooltip
                                                title="No odds available"
                                                className="odds-tooltip"
                                                placement="top"
                                              >
                                                <span className="no-odds">
                                                  NOA
                                                </span>
                                              </Tooltip>
                                            </TableCell>
                                          )}
                                          <TableCell
                                            numeric
                                            align="center"
                                            className={classes.cell}
                                          ></TableCell>
                                        </>
                                      </TableRow>
                                    ) : (
                                      <TableRow>
                                        <StickyTableCell>
                                          <TableCell
                                            numeric
                                            align="right"
                                            className={classes.cell}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <Box
                                              className="racer-detail-wrap"
                                              onClick={() => {
                                                handleTableOpen("isColleps");
                                                setopenSeall(
                                                  openSeall === index
                                                    ? -1
                                                    : index
                                                );
                                                // if (openSeall === -1 || openSeall !== index) {
                                                //   fetchRunnerExtraInfor(item?.id);
                                                // }
                                                if (
                                                  openSeall === -1 ||
                                                  openSeall !== index
                                                ) {
                                                  setOverFLow(false);
                                                } else {
                                                  setOverFLow(true);
                                                }
                                              }}
                                            >
                                              <Typography variant="h6">
                                                <Box
                                                  className="scratched"
                                                  onClick={() => {
                                                    setOpen(
                                                      open === index
                                                        ? -1
                                                        : index
                                                    );
                                                  }}
                                                >
                                                  <span className="scratched-runner-name cursor-pointer">
                                                    {item?.runnerNumber}
                                                    {"."} {item?.animal?.name} (
                                                    {item?.barrierNumber})
                                                  </span>
                                                </Box>
                                                <Box className="scratched-data">
                                                  <span className="scratched-runner-text">
                                                    {" "}
                                                    {
                                                      localesData?.RUNNER_TABLE
                                                        ?.SCRATCHED
                                                    }{" "}
                                                  </span>{" "}
                                                  @{" "}
                                                  <span className="scratched-runner-time">
                                                    {" "}
                                                    {moment(
                                                      item?.updatedAt
                                                    ).format("hh:mm A")}
                                                  </span>
                                                  <span className="scratched-runner-date">
                                                    {" "}
                                                    {moment(
                                                      item?.updatedAt
                                                    ).format("DD/MM/YYYY")}
                                                  </span>
                                                </Box>
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                        </StickyTableCell>
                                        <TableCell colSpan={100}></TableCell>
                                      </TableRow>
                                    )}
                                    <TableRow
                                      style={{
                                        borderBottom: "none",
                                      }}
                                      className="runner-info-tr"
                                    >
                                      <TableCell
                                        colSpan={8}
                                        className="runner-info-td"
                                        style={{
                                          padding: "0 !important",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {!raceParticipantData && (
                                          <Collapse
                                            in={
                                              isSeeallFullFormExpand
                                                ? isSeeallFullFormExpand
                                                : openSeall === index
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            {screenWidth > 799 ? (
                                              <>
                                                {isRunnerLoad ? (
                                                  <div className="allsport-loader-center">
                                                    <Loader />
                                                  </div>
                                                ) : (
                                                  <Box className="runner-info-wrap">
                                                    <Polyhon className="collapse-icon" />
                                                    <Box className="runner-comment">
                                                      <Box className="comment-content">
                                                        <Typography variant="h6">
                                                          {
                                                            localesData
                                                              ?.RUNNER_TABLE
                                                              ?.COLLAPSE
                                                              ?.RUNNER_COMMENTS
                                                          }
                                                        </Typography>
                                                        <Typography variant="body2">
                                                          {item
                                                            ?.RunnerInfos?.[0]
                                                            ?.info
                                                            ?.entrant_comment
                                                            ? item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.entrant_comment
                                                            : "No Comment Available"}
                                                        </Typography>
                                                      </Box>
                                                      <Box className="full-from-btn">
                                                        <Button
                                                          onClick={() =>
                                                            handleOpen(
                                                              runnerInfoData,
                                                              item?.id
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          {
                                                            localesData
                                                              ?.RUNNER_TABLE
                                                              ?.COLLAPSE
                                                              ?.FULL_FORM
                                                          }
                                                        </Button>
                                                      </Box>
                                                      {/* <Box className="win-graph">
                                      <span className="graph-label lb first">
                                        Win
                                      </span>
                                      <span className="graph-label value second">
                                        12%
                                      </span>
                                      <span className="graph-label lb third">
                                        Place
                                      </span>
                                      <span className="graph-label value fourth">
                                        36%
                                      </span>
                                      <PieChart
                                        width={174}
                                        height={176}
                                        // onMouseEnter={this.onPieEnter}
                                      >
                                        <Label>any string or number</Label>
                                        <Pie
                                          data={data}
                                          innerRadius={70}
                                          outerRadius={85}
                                          paddingAngle={-10}
                                          cornerRadius={20}
                                          startAngle={440}
                                          endAngle={80}
                                          dataKey="value"
                                        >
                                          {data.map((entry, index) => (
                                            <Cell
                                              key={`cell-${index}`}
                                              fill={
                                                COLORS[
                                                  index % COLORS.length
                                                ]
                                              }
                                            />
                                          ))}
                                        </Pie>
                                      </PieChart>
                                    </Box> */}
                                                    </Box>
                                                    <Box className="advance-race-detail-wrap">
                                                      <ul>
                                                        <li>
                                                          <span>
                                                            {" "}
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE?.SIRE
                                                            }
                                                            :
                                                          </span>
                                                          <span className="title">
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info?.sire?.name
                                                              ? item
                                                                ?.RunnerInfos?.[0]
                                                                ?.info?.sire
                                                                ?.name
                                                              : "-"}
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <span>
                                                            {" "}
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE?.DAM
                                                            }
                                                            :
                                                          </span>
                                                          <span className="title">
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info?.dam?.name
                                                              ? item
                                                                ?.RunnerInfos?.[0]
                                                                ?.info?.dam
                                                                ?.name
                                                              : "-"}
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <span>
                                                            {" "}
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE?.TYPE
                                                            }
                                                            :
                                                          </span>
                                                          <span className="title">
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.runner_info
                                                              ?.silk_colours
                                                              ? item?.RunnerInfos?.[0]?.info?.runner_info?.silk_colours?.split(
                                                                ","
                                                              )[0]
                                                              : "-"}
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <span>
                                                            {" "}
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE?.AGE
                                                            }
                                                            :
                                                          </span>
                                                          <span className="title">
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.runner_info?.age
                                                              ? item
                                                                ?.RunnerInfos?.[0]
                                                                ?.info
                                                                ?.runner_info
                                                                ?.age + "yo"
                                                              : "-"}
                                                          </span>
                                                        </li>
                                                      </ul>
                                                      <ul>
                                                        <li>
                                                          <span>
                                                            {" "}
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.PRIZE_MONEY
                                                            }
                                                            :
                                                          </span>
                                                          <span className="title">
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.runner_info
                                                              ?.prize_money
                                                              ? formatter.format(
                                                                item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.runner_info
                                                                  ?.prize_money
                                                              )
                                                              : "-"}
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <span>
                                                            {" "}
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.OVERALL
                                                            }
                                                            :
                                                          </span>
                                                          <span className="title">
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.overall?.starts
                                                              ? item
                                                                ?.RunnerInfos?.[0]
                                                                ?.info
                                                                ?.past_runner_performances
                                                                ?.overall
                                                                ?.starts
                                                              : "0"}{" "}
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.overall?.wins
                                                              ? item
                                                                ?.RunnerInfos?.[0]
                                                                ?.info
                                                                ?.past_runner_performances
                                                                ?.overall
                                                                ?.wins
                                                              : "0"}
                                                            -
                                                            {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                            ?.placings
                                            ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.overall
                                                ?.placings
                                            : "0"}
                                          - */}
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.overall?.seconds
                                                              ? item
                                                                ?.RunnerInfos?.[0]
                                                                ?.info
                                                                ?.past_runner_performances
                                                                ?.overall
                                                                ?.seconds
                                                              : "0"}
                                                            -
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.past_runner_performances
                                                              ?.overall?.thirds
                                                              ? item
                                                                ?.RunnerInfos?.[0]
                                                                ?.info
                                                                ?.past_runner_performances
                                                                ?.overall
                                                                ?.thirds
                                                              : "0"}
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <span>
                                                            {" "}
                                                            {
                                                              localesData
                                                                ?.RUNNER_TABLE
                                                                ?.COLLAPSE
                                                                ?.LAST_SIX
                                                            }
                                                            :
                                                          </span>
                                                          <span className="title">
                                                            {item
                                                              ?.RunnerInfos?.[0]
                                                              ?.info
                                                              ?.runner_info
                                                              ?.last_starts
                                                              ? item?.RunnerInfos?.[0]?.info?.runner_info?.last_starts?.slice(
                                                                item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.runner_info
                                                                  ?.last_starts
                                                                  ?.length - 6
                                                              )
                                                              : "-"}
                                                          </span>
                                                        </li>
                                                        <li></li>
                                                      </ul>
                                                    </Box>
                                                    <Box className="runner-detail-tab-wrap">
                                                      <Tabs
                                                        value={value}
                                                        onChange={handleChange}
                                                        aria-label="simple tabs example"
                                                      >
                                                        <Tab label="Short Form" />
                                                        <Tab label="Last Starts" />
                                                      </Tabs>
                                                      <TabPanel
                                                        value={value}
                                                        index={0}
                                                        role="tabpanel"
                                                      >
                                                        <Box className="short-form">
                                                          <ul>
                                                            <li>
                                                              <span>
                                                                {" "}
                                                                {
                                                                  localesData
                                                                    ?.RUNNER_TABLE
                                                                    ?.TRACK
                                                                }
                                                                :
                                                              </span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.track
                                                                  ?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.track
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.track?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.track
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.track
                                                ?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.track?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.track
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.track
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.track
                                                                  ?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.track
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span>
                                                                {" "}
                                                                {
                                                                  localesData
                                                                    ?.RUNNER_TABLE
                                                                    ?.DISTANCE
                                                                }
                                                                :
                                                              </span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.distance
                                                                  ?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.distance
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.distance
                                                                  ?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.distance
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.distance?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.distance?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.distance
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.distance
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.distance
                                                                  ?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.distance
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span>
                                                                Trk/Dst:
                                                              </span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.track_distance
                                                                  ?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.track_distance
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.track_distance
                                                                  ?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.track_distance
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.track_distance?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.track_distance
                                                    ?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.track_distance
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.track_distance
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.track_distance
                                                                  ?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.track_distance
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span>
                                                                1st Up:
                                                              </span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.first_up
                                                                  ?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.first_up
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.first_up
                                                                  ?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.first_up
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.first_up?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.first_up?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.first_up
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.first_up
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.first_up
                                                                  ?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.first_up
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span>
                                                                2nd Up:
                                                              </span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.second_up
                                                                  ?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.second_up
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.second_up
                                                                  ?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.second_up
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.second_up?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.second_up?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.second_up
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.second_up
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.second_up
                                                                  ?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.second_up
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span>Firm:</span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.firm?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.firm
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.firm?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.firm
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                ?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.firm
                                                    ?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.firm
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.firm
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.firm?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.firm
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span>Good:</span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.good?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.good
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.good?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.good
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                ?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.good
                                                    ?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.good
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.good
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.good?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.good
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span>Soft:</span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.soft?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.soft
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.soft?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.soft
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                ?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances?.soft
                                                    ?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.soft
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.soft
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.soft?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.soft
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span>
                                                                Heavy:
                                                              </span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.heavy
                                                                  ?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.heavy
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.heavy?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.heavy
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances?.heavy
                                                ?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.heavy?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.heavy
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.heavy
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.heavy
                                                                  ?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.heavy
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <span>
                                                                Synthetic:
                                                              </span>
                                                              <span className="value">
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.synthetic
                                                                  ?.starts
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.synthetic
                                                                    ?.starts
                                                                  : "0"}{" "}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.synthetic
                                                                  ?.wins
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.synthetic
                                                                    ?.wins
                                                                  : "0"}
                                                                -
                                                                {/* {item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                ?.synthetic?.placings
                                                ? item?.RunnerInfos?.[0]?.info?.past_runner_performances
                                                    ?.synthetic?.placings
                                                : "0"}
                                              - */}
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.synthetic
                                                                  ?.seconds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.synthetic
                                                                    ?.seconds
                                                                  : "0"}
                                                                -
                                                                {item
                                                                  ?.RunnerInfos?.[0]
                                                                  ?.info
                                                                  ?.past_runner_performances
                                                                  ?.synthetic
                                                                  ?.thirds
                                                                  ? item
                                                                    ?.RunnerInfos?.[0]
                                                                    ?.info
                                                                    ?.past_runner_performances
                                                                    ?.synthetic
                                                                    ?.thirds
                                                                  : "0"}
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </Box>
                                                      </TabPanel>
                                                      <TabPanel
                                                        value={value}
                                                        index={1}
                                                        role="tabpanel"
                                                      >
                                                        <Box className="runner-tab-table-head">
                                                          <Typography variant="h6"></Typography>
                                                          <Box className="table-nav">
                                                            <ChevronLeftIcon
                                                              onClick={() =>
                                                                SlideTable(
                                                                  "left"
                                                                )
                                                              }
                                                            />

                                                            <ChevronRightIcon
                                                              onClick={() =>
                                                                SlideTable(
                                                                  "right"
                                                                )
                                                              }
                                                            />
                                                          </Box>
                                                        </Box>

                                                        <TableContainer
                                                          id="runner-tab-table"
                                                          className="runner-tab-table"
                                                        >
                                                          <Table>
                                                            <TableHead>
                                                              <TableRow>
                                                                <TableCell className="table-header">
                                                                  {" "}
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.COLLAPSE
                                                                      ?.PLACE
                                                                  }
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  {" "}
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.COLLAPSE
                                                                      ?.DATE
                                                                  }
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  {" "}
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.TRACK
                                                                  }
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.DISTANCE
                                                                  }
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  Jockey
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  {" "}
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.COLLAPSE
                                                                      ?.WEIGHT
                                                                  }
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  {" "}
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.COLLAPSE
                                                                      ?.MARGIN
                                                                  }
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  SP
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.COLLAPSE
                                                                      ?.CONDITION
                                                                  }
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  {" "}
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.RESULT
                                                                  }
                                                                </TableCell>
                                                                <TableCell className="table-header">
                                                                  {" "}
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.COLLAPSE
                                                                      ?.CLASS
                                                                  }
                                                                </TableCell>
                                                                {/* <TableCell>Gate</TableCell> */}
                                                                <TableCell className="table-header">
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.COLLAPSE
                                                                      ?.RACE_TIME
                                                                  }
                                                                </TableCell>
                                                                {/* <TableCell>
                                                Last 600m
                                              </TableCell> */}
                                                                <TableCell className="table-header">
                                                                  {
                                                                    localesData
                                                                      ?.RUNNER_TABLE
                                                                      ?.COLLAPSE
                                                                      ?.PRIZE_MONEY
                                                                  }
                                                                </TableCell>
                                                                {/* <TableCell>
                                                Run Comment
                                              </TableCell> */}
                                                              </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                              {item
                                                                ?.RunnerInfos?.[0]
                                                                ?.info
                                                                ?.previous_runs
                                                                ?.length > 0 ? (
                                                                item?.RunnerInfos?.[0]?.info?.previous_runs?.map(
                                                                  (item) => {
                                                                    return (
                                                                      <TableRow>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            {item?.finish &&
                                                                              item?.number_of_runners
                                                                              ? item?.finish +
                                                                              "/" +
                                                                              item?.number_of_runners
                                                                              : "-"}
                                                                          </span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            {item?.date
                                                                              ? item?.date
                                                                              : "-"}
                                                                          </span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            {item?.track
                                                                              ? item?.track
                                                                              : "-"}
                                                                          </span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            {item?.distance
                                                                              ? item?.distance +
                                                                              "m"
                                                                              : "-"}
                                                                          </span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            {item?.jockey
                                                                              ? item?.jockey
                                                                              : "-"}
                                                                          </span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            {item?.weight_carried
                                                                              ? item?.weight_carried
                                                                              : "-"}
                                                                          </span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            {item?.margin
                                                                              ? item?.margin +
                                                                              "L"
                                                                              : "-"}
                                                                          </span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            {item?.starting_price
                                                                              ? "$" +
                                                                              item?.starting_price
                                                                              : "-"}
                                                                          </span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            Heavy
                                                                          </span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="result table-cell"
                                                                        >
                                                                          {item?.winner ? (
                                                                            <span>
                                                                              {
                                                                                "1."
                                                                              }{" "}
                                                                              {
                                                                                item?.winner
                                                                              }
                                                                            </span>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                          {item?.second ? (
                                                                            <span className="disable">
                                                                              {
                                                                                "2."
                                                                              }{" "}
                                                                              {
                                                                                item?.second
                                                                              }
                                                                            </span>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                          {item?.third ? (
                                                                            <span className="disable">
                                                                              {
                                                                                "3."
                                                                              }{" "}
                                                                              {
                                                                                item?.third
                                                                              }
                                                                            </span>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                        </TableCell>
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          <span>
                                                                            {item?.class
                                                                              ? item?.class
                                                                              : "-"}
                                                                          </span>
                                                                        </TableCell>
                                                                        {/* <TableCell align="left">
                                                      <span>{item?.gate ? item?.gate : "-"}</span>
                                                    </TableCell> */}
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          {item?.time
                                                                            ? item?.time
                                                                            : "-"}
                                                                        </TableCell>
                                                                        {/* <TableCell align="left">
                                                    {item?.last ? item?.last : "-"}
                                                    </TableCell> */}
                                                                        <TableCell
                                                                          align="left"
                                                                          className="table-cell"
                                                                        >
                                                                          {item?.prize_money
                                                                            ? formatter.format(
                                                                              item?.prize_money
                                                                            )
                                                                            : "-"}
                                                                        </TableCell>
                                                                        {/* <TableCell align="left">
                                                      <span>
                                                      {item?.comment ? item?.comment : "-"}
                                                      </span>
                                                    </TableCell> */}
                                                                      </TableRow>
                                                                    );
                                                                  }
                                                                )
                                                              ) : (
                                                                <TableCell
                                                                  align="center"
                                                                  className="table-cell"
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                  colSpan={100}
                                                                >
                                                                  {
                                                                    localesData
                                                                      ?.sport
                                                                      ?.NO_DATA
                                                                  }
                                                                </TableCell>
                                                              )}
                                                            </TableBody>
                                                          </Table>
                                                        </TableContainer>
                                                      </TabPanel>
                                                    </Box>
                                                  </Box>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {fetchMobileViewRunnerModal(
                                                  item
                                                )}
                                              </>
                                            )}
                                          </Collapse>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <TableRow style={{ border: "transparent" }}>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  border: "transparent",
                                }}
                                colSpan={21}
                              >
                                {/* {localesData?.sport?.NO_DATA} */}
                                <NoDataComp />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </>
              )
            ) : (
              <Box className="race-bookmakersodd featured-table">
                {isrunnnerLoading || oddsLoader ? (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <Box className="racing-data">
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          columnGap: "18px",
                          alignItems: "center",
                        }}
                        className="runnertable-header"
                      >
                        <span>
                          {localesData?.RUNNER_TABLE?.RUNNER}
                          <Order
                            className="player-order"
                            onClick={handleRunnerOrder}
                          />
                        </span>
                        <span
                          className="ex-full"
                          onClick={() => handleExpandfullForm()}
                        >
                          {localesData?.RUNNER_TABLE?.EXPAND_FULL_FORM}
                        </span>

                        {/* <span className="ex-full see-filter" onClick={handleChangeTable}>
                                                    {seeall != true ? localesData?.RUNNER_TABLE?.SEE_ALL : localesData?.RUNNER_TABLE?.SEE_BEST}
                                                </span> */}
                      </Box>
                      {runnerInfoData?.length > 0 ? (
                        <>
                          {runnerInfoData?.map((item, index) => {
                            return (
                              <>
                                <Box
                                  // className="left-runner-details"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box>
                                    <Box className="table-runner-info">
                                      <Box
                                        className="collapse-icon"
                                        onClick={() => {
                                          setOpen(open === index ? -1 : index);
                                        }}
                                      >
                                        {open === index || isFullFormExpand ? (
                                          <CollepseOpen />
                                        ) : (
                                          <CollepseClose />
                                        )}
                                      </Box>
                                      <Box
                                        className="racer-detail-wrap"
                                        onClick={() => {
                                          setOpen(open === index ? -1 : index);
                                        }}
                                      >
                                        <ClickAwayListener
                                          onClickAway={() =>
                                            handleOutsideClick(item, index)
                                          }
                                        >
                                          <Box
                                            className="mobile-blackbook"
                                            onClick={() => {
                                              setOpen(
                                                open === index ? -1 : index
                                              );
                                            }}
                                          >
                                            <Typography
                                              variant="h6"
                                              className="mobile-racer-info cursor-pointer"
                                            >
                                              {item?.runnerNumber}
                                              {"."} {item?.animal?.name} (
                                              {item?.barrierNumber})
                                            </Typography>
                                            {handleOpenTooltip(item, index)}
                                          </Box>
                                        </ClickAwayListener>
                                        <Box className="player-detail-container">
                                          <Box className="player-wrap">
                                            <Box className="player-detail">
                                              {item?.Jockey ? (
                                                <>
                                                  <span className="text-bold">
                                                    {sportId === "2"
                                                      ? `D: `
                                                      : `J: `}
                                                  </span>
                                                  <span>
                                                    {item?.Jockey?.name}
                                                  </span>
                                                </>
                                              ) : item?.Trainer ? (
                                                <>
                                                  <span className="text-bold">
                                                    T:{" "}
                                                  </span>
                                                  <span>
                                                    {item?.Trainer?.name}
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Box>
                                            {sportId === "1" ? (
                                              <Box className="player-detail">
                                                <span className="text-bold">
                                                  W:{" "}
                                                </span>
                                                <span>
                                                  {Number(
                                                    item?.JockeyWeight
                                                  ).toFixed(2) + "Kg"}{" "}
                                                </span>
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                          </Box>
                                          <Box className="player-wrap">
                                            {sportId === "1" ||
                                              sportId === "2" ? (
                                              <Box className="player-detail">
                                                {item?.Trainer ? (
                                                  <>
                                                    <span className="text-bold">
                                                      T:{" "}
                                                    </span>
                                                    <span>
                                                      {item?.Trainer?.name}
                                                    </span>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                  {screenWidth < 799 && (
                                    <Box
                                      className={`odds-value-wrap align-right  current-best-odds-wrap ${localAuth ? "" : "blur-comp"}`}
                                    >
                                      {/* <Box className="current-best-odds-value"> */}{" "}
                                      {fetchCurrentBestValue(
                                        item?.oddsData?.[0]?.data,
                                        item
                                      )}
                                      {/* </Box> */}
                                      {/* <Box className="current-best-odds-icon"> */}
                                      {fetchCurrentBestIcon(
                                        item?.oddsData?.[0]?.data
                                      )}
                                      {/* </Box> */}
                                    </Box>
                                  )}
                                </Box>
                                <Box style={{ marginBottom: "10px" }}>
                                  <Collapse
                                    in={
                                      isFullFormExpand
                                        ? isFullFormExpand
                                        : open === index
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {fetchMobileViewRunnerModal(item)}
                                  </Collapse>
                                </Box>
                                {seeall != true ? (
                                  <Box className="mobile-table-container">
                                    <TableContainer>
                                      <Table>
                                        <TableHead className="mobile-data-head">
                                          {/* <TableCell className="mobile-table-data " width="33.33%"> {localesData?.RUNNER_TABLE?.BEST_AT_OPEN}</TableCell> */}
                                          {/* <TableCell className="mobile-table-data current-best" width="33.33%"> {localesData?.RUNNER_TABLE?.CURRENT_BEST}</TableCell> */}
                                          <TableCell
                                            className="mobile-table-data sponsor-odds-header"
                                            width="33.33%"
                                          >
                                            {" "}
                                            Featured bookmaker Odds
                                          </TableCell>
                                        </TableHead>
                                        <TableBody className="mobile-table-body">
                                          {/* <TableCell className="mobile-table-data odds-column">
                                                                                        {item?.oddsData?.[0]?.data ? (
                                                                                            <>
                                                                                                <Box>
                                                                                                    {fetchBestOpenValue(item?.oddsData?.[0]?.data, 0)}
                                                                                                </Box>
                                                                                                {fetchBestOpenIcon(item?.oddsData?.[0]?.data, 0)}
                                                                                            </>
                                                                                        ) : ("-")}</TableCell> */}
                                          {/* <TableCell className="mobile-table-data current-best-odds-column">
                                                                                        {item?.oddsData?.[0]?.data ? (
                                                                                            <>
                                                                                                <Box>
                                                                                                    {fetchCurrentBestValue(item?.oddsData?.[0]?.data, item)}
                                                                                                </Box>
                                                                                                {fetchCurrentBestIcon(item?.oddsData?.[0]?.data)}
                                                                                            </>
                                                                                        ) : ("-")}
                                                                                    </TableCell> */}
                                          <TableCell className="mobile-table-data current-best-odds-column gray-bg">
                                            {!isLoading &&
                                              topBookMakersData?.length ===
                                              0 && (
                                                <p
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No Data Available
                                                </p>
                                              )}
                                            {!isLoading &&
                                              topBookMakersData?.length > 0 && (
                                                <Box className="hide-scroll max-w">
                                                  <Box className="odds-flex">
                                                    {topBookMakersData
                                                      ?.slice(0, 6)
                                                      ?.map(
                                                        (bookkeper, index) => {
                                                          return fetchFeaturedOdds(
                                                            item?.oddsData?.[0]
                                                              ?.data,
                                                            bookkeper?.id,
                                                            item
                                                          );
                                                        }
                                                      )}
                                                  </Box>
                                                </Box>
                                              )}
                                          </TableCell>
                                          {sponsoredId?.length > 0 ? (
                                            <>
                                              <TableCell className="next5odds-icon odds-column">
                                                <Box className="sponsored-odds">
                                                  {fetchSponsoredOdds(
                                                    item?.oddsData?.[0]?.data,
                                                    item
                                                  )}
                                                </Box>
                                              </TableCell>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {/* {isResult ? (
                                      <>
                                        {fetchTop5Odds(item?.oddsData?.[0]?.data, isResult)}
                                      </>
                                    ) : (<></>)
                                    } */}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Box>
                                ) : (
                                  <Box className="mobile-best5odds-wrap">
                                    <Box className="mobile-best5odds-select">
                                      {/* <Typography>{localesData?.sport?.SEE_ALL_ODDS}</Typography> */}
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() =>
                                          setMobileBest5Open(
                                            MobileBest5Open === index
                                              ? -1
                                              : index
                                          )
                                        }
                                      >
                                        {MobileBest5Open === index ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </Box>
                                    <Box>
                                      <Collapse
                                        in={MobileBest5Open === index}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <TableContainer>
                                          <Table>
                                            <TableHead></TableHead>
                                            <TableBody>
                                              <TableRow
                                                style={{ overflowX: "scroll" }}
                                              >
                                                {pageHeadingData?.length > 0 ? (
                                                  pageHeadingData?.map((id) =>
                                                    fetchMobileViewSeeAllOdds(
                                                      item,
                                                      id?.BookKeeperId
                                                    )
                                                  )
                                                ) : (
                                                  <TableCell
                                                    style={{ border: "none" }}
                                                  >
                                                    <Tooltip
                                                      title="No odds available"
                                                      className="odds-tooltip"
                                                      placement="top"
                                                    >
                                                      <span className="no-odds">
                                                        NOA
                                                      </span>
                                                    </Tooltip>
                                                  </TableCell>
                                                )}
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Collapse>
                                    </Box>
                                  </Box>
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <Box
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {/* {localesData?.sport?.NO_DATA} */}
                          <NoDataComp />
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            )}
            {isInputModalOpen && (
              <RunnerModal
                runnerDatas={modalData}
                open={isInputModalOpen}
                onClose={() => toggleInputModal()}
                ModalDataId={ModalDataId}
              />
            )}
          </Box>
        </Box>
      ) : (
        // :
        // runnerTabValue === 1 ? "speedmap"
        <FluctuationGraphPage
          runnerInfo={runnerInfo}
          isrunnnerLoading={isrunnnerLoading}
          raceId={raceId}
          isResult={isResult}
          fetchRaceRunner={fetchRaceRunner}
        />
      )}
      <BlackBookModal
        open={BBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        listingFunction={() => {
          fetchRaceRunner(raceId);
          clearInterval(timeinterval);
          clearTimeout(apiTimeout);
        }}
      />
      {/* <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        /> */}
    </>
  );
};
